const qpstat_initstate = [];
const initstate = {
  fphotopath: "",
  fqpcode: "",
  fpacket: "",
  fedit: "F",
  table: [
    { slno: "01", mks: -1 },
    { slno: "02", mks: -1 },
    { slno: "03", mks: -1 },
    { slno: "04", mks: -1 },
    { slno: "05", mks: -1 },
    { slno: "06", mks: -1 },
    { slno: "07", mks: -1 },
    { slno: "08", mks: -1 },
    { slno: "09", mks: -1 },
    { slno: "10", mks: -1 },
    { slno: "11", mks: -1 },
    { slno: "12", mks: -1 },
    { slno: "13", mks: -1 },
    { slno: "14", mks: -1 },
    { slno: "15", mks: -1 },
    { slno: "16", mks: -1 },
    { slno: "17", mks: -1 },
    { slno: "18", mks: -1 },
    { slno: "19", mks: -1 },
    { slno: "20", mks: -1 },
  ],
};

export const getRVMarksEntry = (state = qpstat_initstate, action) => {
  switch (action.type) {
    case "RV_ENTRY":
      return action.payload.data;
    case "DELETE_QPSTATS":
      return qpstat_initstate;
    default:
      return state;
  }
};

export const getdocdetRVreducer = (state = initstate, action) => {
  switch (action.type) {
    case "GET_DOC_DET_RV":
      function pad(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      }
      const table = action.payload.data.prevdata.map((el, i) => {
        return {
          slno: pad(i + 1, 2),
          mks: el.fmarks11,
        };
      });
      if (
        action.payload.data.prevdata == undefined ||
        action.payload.data.prevdata == ""
      ) {
        return {
          ...initstate,
          fphotopath: action.payload.data.scandocs.fdocpath,
        };
      }

      return {
        fphotopath: action.payload.data.scandocs.fdocpath,
        fqpcode: action.payload.data.prevdata[0].fqpcode,
        fpacket: action.payload.data.prevdata[0].fpacket,
        table: table,
        fedit: "T",
      };
    case "RV_MARKS_CHANGE":
      const changemks = state.table.map((el, i) => {
        if (action.payload.i == i) {
          return { slno: el.slno, mks: action.payload.data };
        } else {
          return el;
        }
      });
      return { ...state, table: changemks };
    case "RV_MARKS_QPCODE_CHANGE":
      return { ...state, fqpcode: action.payload.data };
    case "RV_MARKS_PACKET_CHANGE":
      return { ...state, fpacket: action.payload.data };
    default:
      return state;
  }
};

// export const getEditdocdetreducer = (state = qpstat_initstate, action) => {
//   switch (action.type) {
//     case "GET_DOC_DET_RV_EDIT":
//       return action.payload.data;
//     default:
//       return state;
//   }
// };
