export const getdeggrpresult = (state = [], action) => {
  switch (action.type) {
    case "GET_UNIVERSITY_DATA":
      return action.payload.data;
    case "CLEAR_UNIVERSITY_DATA":
      return [];
    default:
      return state;
  }
};

export const getExamdateResult = (state = [], action) => {
  switch (action.type) {
    case "GET_EXAMDATE_DATA":
      return action.payload.data;
    case "CLEAR_EXAMDATE_DATA":
      return [];
    default:
      return state;
  }
};

export const getDegreeResult = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_RESULT_DATA":
      return action.payload.data;
    case "CLEAR_DEGREE_RESULT_DATA":
      return [];
    default:
      return state;
  }
};

export const getSemResult = (state = [], action) => {
  switch (action.type) {
    case "GET_SEM_RESULT_DATA":
      return action.payload.data;
    case "CLEAR_SEM_RESULT_DATA":
      return [];
    default:
      return state;
  }
};

export const viewResultDate = (state = [], action) => {
  switch (action.type) {
    case "GET_RESULT_DATE":
      return action.payload || state;
    case "CLEAR_RESULT_DATE":
      return [];
    default:
      return state;
  }
};
