export const loadQPDet = (state = [], action) => {
  switch (action.type) {
    case "LOAD_QP_CODE_DET":
      return action.payload.data;
    case "CLEAR_QP_CODE_DET":
      return [];
    default:
      return state;
  }
};

export const totUploadAnsBookDet = (state = [], action) => {
  switch (action.type) {
    case "TOT_ANS_BOOK":
      return action.payload.data;
    case "CLEAR_TOT_ANS_BOOK":
      return [];
    default:
      return state;
  }
};

export const getYearModeData = (state = [], action) => {
  switch (action.type) {
    case "GET_YER_MODE":
      return action.payload.data;
    case "CLEAR_YER_MODE":
      return [];
    default:
      return state;
  }
};
