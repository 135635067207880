const initstate = [];

export const setpracticalexamcenter = (state = initstate, action) => {
  switch (action.type) {
    case "PRACTICAL_CENTERS_CHANGE":
      const oldcentern = state.map((el, i) => {
        if (action.payload.i == i) {
          return action.payload.el;
        } else {
          return el;
        }
      });
      return [...oldcentern];

    case "PRACTICAL_CENTERS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getcollegelistpractical = (state = [], action) => {
  switch (action.type) {
    case "ALL_COLLEGE_LIST":
      return action.payload.data;
    case "CLEAR_ALL_COLLEGE_LIST":
      return [];
    default:
      return state;
  }
};

export const getsubjectlistpractical = (state = [], action) => {
  switch (action.type) {
    case "ALL_SUBJECT_LIST":
      return action.payload.data;
    case "CLEAR_ALL_SUBJECT_LIST":
      return [];
    default:
      return state;
  }
};

export const getcentrecodepractical = (state = [], action) => {
  switch (action.type) {
    case "ALL_CENTRE_LIST":
      return action.payload.data;
    case "CLEAR_ALL_CENTRE_LIST":
      return [];
    default:
      return state;
  }
};
