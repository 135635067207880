export const getmarksentrydet = (state = [], action) => {
  switch (action.type) {
    case "MARKS_ENTRY":     
      return action.payload.data;
    case "CLEAR_MARKS_ENTRY":
      return [];
    default:
      return state;
  }
};

export const updatemarksentrydet = (state = [], action) => {
    
  switch (action.type) {
    case "UPDATE_MARKS_ENTRY":
      return action.payload.data;
    case "CLEAR_UPDATE_MARKS_ENTRY":
      return [];
    default:
      return state;
  }
};
