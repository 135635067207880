const initistate = {
  level: [],
  flevel: "",
  fdeggrp: "",
  degree: [],
  fdegree: "",
  college: [],
  fcollcode: "",
  fgrptype: "A",
  fcommtype: "Single",
};

export const grpCreation = (state = initistate, action) => {
  switch (action.type) {
    case "getLevel":
      const fcoll = action.payload.data.fcollege;
      const fdeggrp = action.payload.data.fdeggrp;
      const flevels = action.payload.data.flevel;
      return { ...state, fcollege: fcoll, fdeggrp: fdeggrp, flevel: flevels };
    case "GRP_GET_COLLEGES":
      const item = { fcollcode: "All", college_name: "All" };
      return { ...state, college: [item, ...action.payload.data] };
    case "GRP_GET_DEGREES":
      const ditem = { fdegree: "All", fdescpn: "All" };
      return { ...state, degree: [ditem, ...action.payload.data] };
    case "CHANGE_GRPCRE":
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case "CANCEL_GRPCRE":
      return initistate;
    default:
      return state;
  }
};

export const getDeg = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGR":
      return action.payload.data;
    case "CLR_DEGR":
      return [];
    default:
      return state;
  }
};

export const grpFormData = (state = [], action) => {
  switch (action.type) {
    case "GRP_GET_FORMDATA":
      return [...action.payload.data];
    case "CHANGE_GRPDATA":
      const { newel, fregno } = action.payload;
      const newState = state.map((el, i) => {
        if (el.fcode == fregno) return newel;
        else return el;
      });
      return [...newState];
    case "CLEAR_GRPCRE":
      return [];
    default:
      return state;
  }
};
