export const getDegSemList = (state = [], action) => {
  switch (action.type) {
    case "GET_DEG_SEM_LIST":
      return action.payload.data;

    default:
      return state;
  }
};
const initdegsem = {
  FABSZERO: "",
  FAGGRMIN: "",
  FAGMINSUB: "",
  FALTDEG: "",
  FATTALL: "",
  FBLK1CLASS: "",
  FBLKNAME01: "",
  FBLKNAME02: "",
  FBLKNAME03: "",
  FBLKNAME04: "",
  FBLKNAME05: "",
  FBLKNAME06: "",
  FBLKNAME07: "",
  FBLKNAME08: "",
  FBLKPASS: "",
  FBLKSUB01: "",
  FBLKSUB02: "",
  FBLKSUB03: "",
  FBLKSUB04: "",
  FBLKSUB05: "",
  FBLKSUB06: "",
  FBLKSUB07: "",
  FBLKSUB08: "",
  FBRANCHDESCPN: "",
  FCLASS: "",
  FCLASS1PER: "",
  FCLASS2PER: "",
  FCLASSATT: "",
  FCLBLKRNG: "",
  FCLGRPER: "",
  FCLGRSEP: "",
  FCLOSEMC: "",
  FCLUBEXAM: "",
  FCMAXTERMS: "",
  FCOLLDEPT: "",
  FCONTMARKS: "",
  FCRCC: "",
  FCREDIT: "",
  FCUMAGMIN: "",
  FCUMCLASS: "",
  FCUMRESULT: "",
  FCUMULAT: "",
  FDEGDESCPN: "",
  FDEGGRP: "",
  FDEGREE: "",
  FDELETED: "",
  FDESCPN: "",
  FDEVINCL: "",
  FDEVMARK: "",
  FDEVPER: "",
  FDISTPER: "",
  FELIGREM01: ``,
  FELIGREM02: "",
  FEXAMDATE: "",
  FEXAMGRACE: "",
  FEXAMNAME: "",
  FEXAMNO: "",
  FEXAMPASS: "",
  FEXAMTYPE: "",
  FFACULTY: "",
  FFIRSTATT: "",
  FFIRSTEXAM: "",
  FGIVEGRACE2: "",
  FGRACEPER: "",
  FGRACESUB: "",
  FGRADE: "",
  FGRADENAME11: "",
  FGRADENAME12: "",
  FGRADENAME21: "",
  FGRADENAME22: "",
  FGRADENAME31: "",
  FGRADENAME32: "",
  FGRREFNO: "",
  FINITQP: "",
  FKANBRANCHDESCPN: "",
  FKANDEGDESCPN: "",
  FLEASTAPP: "",
  FLEASTEXAM: "",
  FLITERACY: "",
  FMAXACTIVE: "",
  FMAXAPP: "",
  FMAXATT: "",
  FMAXMARK01: "",
  FMAXMARK02: "",
  FMAXMARK03: "",
  FMAXMARK04: "",
  FMAXMARK05: "",
  FMAXMARK06: "",
  FMAXMARK07: "",
  FMAXMARK08: "",
  FMAXMARKS: "",
  FMAXTERMS: "",
  FMAXTYPE: "",
  FMCTYPE: "",
  FMINBLK: "",
  FMINMARK01: "",
  FMINMARK02: "",
  FMINMARK03: "",
  FMINMARK04: "",
  FMINMARK05: "",
  FMINMARK06: "",
  FMINMARK07: "",
  FMINMARK08: "",
  FMINMARKS: "",
  FMINPASS01: "",
  FMINPASS02: "",
  FMINPASS03: "",
  FMINPASS04: "",
  FMINPASS05: "",
  FMINPASS06: "",
  FMINPASS07: "",
  FMINPASS08: "",
  FMINTOT: "",
  FMODREFNO: "",
  FMRPGRMARK: "",
  FNEXTEXAM: "",
  FNORANKS: "",
  FNOTERMS: "",
  FNOVAL: "",
  FPASSPER: "",
  FPERSET: "",
  FPRGRACE: "",
  FPRTOTFAIL: "",
  FRANK: "",
  FRANKATT: "",
  FREDGRACE: "",
  FREGREFNO: "",
  FREPGRMARK: "",
  FREPTERMS: "",
  FREXAMTYPE: "",
  FRYEAR: "",
  FSCHEME: "",
  FSEPREPGR: "",
  FSGLENTRY: "",
  FSKIPFAIL: "",
  FSNINLED: "",
  FSPLITEXAM: "",
  FSUBCHOICE: "",
  FSUBELIG: "",
  FSUBEX: "",
  FSUBFAIL: "",
  FSUBGRMARK: "",
  FSUBGRPER: "",
  FSUBSIFAIL: "",
  FTHAGONLY: "",
  FTHPRACSEP: "",
  FTHPRCR: "",
  FTOTGRACE: "",
  FTOTSUB: "",
  FUSED: "",
  FVARSUB: "",
  FYEAR: "",
};

export const getDegSemEdit = (state = initdegsem, action) => {
  switch (action.type) {
    case "GET_DEG_SEM_EDIT":
      return action.payload.data.degree;
    case "CHANGE_DEG_SEM_DATA":
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case "EPTY_DEG_SEM_DATA":
      return initdegsem;
    default:
      return state;
  }
};

const initdegclass = [
  {
    FSLNO: "1",
    FFROMATT: "",
    FTOATT: "",
    FFROMPER: "",
    FTOPER: "",
    FCLASS: "",
    FCLSGRADE: "",
    FCLSGROUP: "",
  },
];

export const getDegClassEdit = (state = initdegclass, action) => {
  switch (action.type) {
    case "GET_DEG_SEM_EDIT":
      if (action.payload.data.degclass.length > 0)
        return action.payload.data.degclass;
      else return initdegclass;
    case "CHANGE_DEG_CLASS_DATA":
      let { el, i } = action.payload;
      return state.map((item, j) => {
        let data = item;
        if (j === i) {
          data = { ...el };
        }
        return data;
      });
    case "ADD_DEG_CLASS_DATA":
      return [
        ...state,
        {
          FSLNO: state.length + 1,
          FFROMATT: "",
          FTOATT: "",
          FFROMPER: "",
          FTOPER: "",
          FCLASS: "",
          FCLSGRADE: "",
          FCLSGROUP: "",
        },
      ];
    case "EPTY_DEG_SEM_DATA":
      return initdegclass;
    default:
      return state;
  }
};

export const getDegReason = (state = [], action) => {
  switch (action.type) {
    case "GET_DEG_SEM_EDIT":
      if (action.payload.data.reason.length > 0)
        return action.payload.data.reason;
      else return [];
    default:
      return state;
  }
};
