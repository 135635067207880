export const studentadmission = (state = [], action) => {
  switch (action.type) {
    case "FETCH_ST_fetchStudentReport":
      return action.payload.data;
    case "CLEAR_FETCH_ST_fetchStudentReport":
      return [];
    default:
      return state;
  }
};
