export const generateDegreeDetails = (state = [], action) => {
  switch (action.type) {
    case "GENERATE_DEGREE_DETAILS":
      return action.payload.data;
    case "CLEAR_GENERATE_DEGREE_DETAILS":
      return [];
    default:
      return state;
  }
};
