const initstate = {
  data: [],
};
var final2 = [];
var final3 = [];
export const getValInviReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_VAL_INVI":
      return { ...state, ...action.payload.data };
    case "CHANGE_INVITE_DATA":
      let { el, i } = action.payload;
      const new_arr = state.taechdet.map((item, j) => {
        let data = item;
        if (j === i) {
          return el;
        } else return item;
      });

      return { taechdet: [...new_arr] };

    case "CHANGE_ALL":
      const { data, finaldata } = action.payload;
      if (action.payload.el.checked) {
        const new_arr2 = action.payload.i.map(async (item) => {
          await final2.push({
            fteachcode: item.fteachcode,
            FTEACHNAME: item.FTEACHNAME,
            FSCALE: item.FSCALE,
            fmobile: item.fmobile,
            femail: item.femail,
            fboard: item.fboard,
            finvited: "T",
          });
        });

        return { taechdet: [...final2] };
      }
      if (!action.payload.el.checked) {
        const new_arr3 = action.payload.i.map(async (item) => {
          await final3.push({
            fteachcode: item.fteachcode,
            FTEACHNAME: item.FTEACHNAME,
            FSCALE: item.FSCALE,
            fmobile: item.fmobile,
            femail: item.femail,
            fboard: item.fboard,
            finvited: "F",
          });
        });
        return { taechdet: [...final3] };
      }

    default:
      return state;
  }
};

export const saveInvitation = (state = [], action) => {
  switch (action.type) {
    case "SAVE_INVITE_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const saveInviteList = (state = [], action) => {
  switch (action.type) {
    case "SAVE_INVITE_LIST":
      return action.payload;

    default:
      return state;
  }
};

export const sendMsg = (state = [], action) => {
  switch (action.type) {
    case "SVAE_SEND_MESSAGE":
      return action.payload;

    default:
      return state;
  }
};

export const sendMsgAll = (state = [], action) => {
  switch (action.type) {
    case "SEND_ALL_MSG":
      return action.payload;
    default:
      return state;
  }
};
