export const getNotificationHeads = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFICATION_HEADS":
      return action.payload.data;
    case "CLEAR_NOTIFICATION_HEADS":
      return [];
    default:
      return state;
  }
};

export const getNotificationDegree = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFICATION_DEGREE":
      return action.payload.data;
    case "CLEAR_NOTIFICATION_DEGREE":
      return [];
    default:
      return state;
  }
};

export const getNotificationSem = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFICATION_SEM":
      return action.payload.data;
    case "CLEAR_NOTIFICATION_SEM":
      return [];
    default:
      return state;
  }
};

export const getNotificationHeadDet = (state = [], action) => {
  switch (action.type) {
    case "GET_UPCOMINGEVENT_DET":
      return action.payload.data;
    case "CLEAR_UPCOMINGEVENT_DET":
      return [];
    default:
      return state;
  }
};
