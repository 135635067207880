const initstate = { newsub: [], oldsub: [] };

const subtabledatareducer = (state = initstate, action) => {
  switch (action.type) {
    case "SUB_MAP_CHANGE":
      const oldsubn = state.oldsub.map((el, i) => {
        if (action.payload.i == i) {
          return action.payload.el;
        } else {
          return el;
        }
      });
      return { ...state, oldsub: oldsubn };
    case "SUB_MAP":
      return action.payload.data;
    default:
      return state;
  }
};

export { subtabledatareducer };
