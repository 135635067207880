export const viewAtteBlock = (state = [], action) => {
  switch (action.type) {
    case "GET_ATT_BLOCK":
      return action.payload.data;
    case "CLEAR_ATT_BLOCK":
      return [];
    default:
      return state;
  }
};

export const saveAttBlock = (state = [], action) => {
  switch (action.type) {
    case "GET_SAVE_ATT_BLOCK":
      return action.payload.data;
    case "CLEAR_SAVE_ATT_BLOCK":
      return [];
    default:
      return state;
  }
};
