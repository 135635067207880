export const dailyValuation = (state = [], action) => {
  switch (action.type) {
    case "GET_DAILY_VALUATION":
      return action.payload.data;

    case "CLEAR_DAILY_VALUATION":
      return [];
    default:
      return state;
  }
};

export const getfboarddailyval = (state = [], action) => {
  switch (action.type) {
    case "GET_DROP_DOWN_OPTIONS_FBOARD":
      return action.payload.data;

    default:
      return state;
  }
};

export const getdegAll = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGG_DEGREE_DATA":
      return action.payload.data;
    case "CLEAR_DEGG_DEGREE_DATA":
      return [];
    default:
      return state;
  }
};

export const getCollegeOnDeggrp = (state = [], action) => {
  switch (action.type) {
    case "COLLEGE_DEGGRP":
      return action.payload;
    case "CLEAR_COLLEGE_DEGGRP":
      return [];
    default:
      return state;
  }
};

export const getDegreeOnDeggrpCollege = (state = [], action) => {
  switch (action.type) {
    case "DEGREE_COLLEGE_DEGGRP":
      return action.payload;
    case "CLEAR_DEGREE_COLLEGE_DEGGRP":
      return [];
    default:
      return state;
  }
};
