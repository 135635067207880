export const getcollegeiamarks = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getdegreeia = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getsemia = (state = [], action) => {
  switch (action.type) {
    case "GET_SEM_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getsubjectia = (state = [], action) => {
  switch (action.type) {
    case "GET_SUB_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const viewiamarkstable = (state = [], action) => {
  switch (action.type) {
    case "GET_IA_TABLE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
