export const examDate = (state = [], action) => {
  switch (action.type) {
    case "GET_EXAM_DATE":
      return action.payload.data;
    case "CLEAR_EXAM_DATE":
      return [];
    default:
      return state;
  }
};

export const getBoardDet = (state = [], action) => {
  switch (action.type) {
    case "GET_BOARD_DET":
      return action.payload.data;
    case "CLEAR_BOARD_DET":
      return [];
    default:
      return state;
  }
};

export const getBoardSubj = (state = [], action) => {
  switch (action.type) {
    case "GET_BOARD_SUBJ":
      return action.payload.data;
    case "CLEAR_BOARD_SUBJ":
      return [];
    default:
      return state;
  }
};

export const getTeachDet = (state = [], action) => {
  switch (action.type) {
    case "GET_TEACH_DETAILS":
      return action.payload.data;
    case "CLEAR_TEACH_DETAILS":
      return [];
    default:
      return state;
  }
};

export const saveQPSeter = (state = [], action) => {
  switch (action.type) {
    case "GET_SAVE_QP_DETAILS":
      return action.payload.data;
    case "CLEAR_QP_DETAILS":
      return [];
    default:
      return state;
  }
};

export const getSelTeachDet = (state = [], action) => {
  switch (action.type) {
    case "GET_SEL_TEC_DETAILS":
      return action.payload.data;
    case "CLEAR_SEL_TEC_DETAILS":
      return [];
    default:
      return state;
  }
};

export const saveSelQPSeter = (state = [], action) => {
  switch (action.type) {
    case "GET_SAVE_SEL_QP":
      return action.payload.data;
    case "CLEAR_SAVE_SEL_QP":
      return [];
    default:
      return state;
  }
};

export const getQPDetails = (state = [], action) => {
  switch (action.type) {
    case "GET_QUSPAP_DET":
      return action.payload.data;
    case "CLEAR_QUSPAP_DET":
      return [];
    default:
      return state;
  }
};

export const displayQPDetails = (state = [], action) => {
  switch (action.type) {
    case "DIS_QUSPA_DET":
      return action.payload.data;
    case "CLEAR_DIS_QUSPA_DET":
      return [];
    default:
      return state;
  }
};

export const disAppQPDetails = (state = [], action) => {
  switch (action.type) {
    case "DIS_APP_QUS_DET":
      return action.payload.data;
    case "CLEAR_DIS_APP_QUS_DET":
      return [];
    default:
      return state;
  }
};

export const saveBankDetails = (state = [], action) => {
  switch (action.type) {
    case "QP_BANK_DET":
      return action.payload.data;
    case "CLEAR_QP_BANK_DET":
      return [];
    default:
      return state;
  }
};

export const getBankDet = (state = [], action) => {
  switch (action.type) {
    case "TEACH_BANK_DET":
      return action.payload.data;
    case "CLEAR_TEACH_BANK_DET":
      return [];
    default:
      return state;
  }
};

export const acceptQPDet = (state = [], action) => {
  switch (action.type) {
    case "ACCEPT_QP_DET":
      return action.payload.data;
    case "CLEAR_ACCEPT_QP_DET":
      return [];
    default:
      return state;
  }
};

export const acceptedUploadQP = (state = [], action) => {
  switch (action.type) {
    case "GET_ACCEPT_QP_DET":
      return action.payload.data;
    case "CLEAR_GET_ACCEPT_QP_DET":
      return [];
    default:
      return state;
  }
};

export const getFinalQpData = (state = [], action) => {
  switch (action.type) {
    case "GET_FINAL_QP":
      return action.payload.data;
    case "CLEAR_FINAL_QP":
      return [];
    default:
      return state;
  }
};

export const getTeacherAllData = (state = [], action) => {
  switch (action.type) {
    case "TEACHER_MOD_DATA":
      return action.payload.data;
    case "CLEAR_TEACHER_MOD_DATA":
      return [];
    default:
      return state;
  }
};

export const submitQPDet = (state = [], action) => {
  switch (action.type) {
    case "QP_DETAILS_TAB":
      return action.payload.data;
    case "CLEAR_QP_DETAILS_TAB":
      return [];
    default:
      return state;
  }
};

export const getProgramDet = (state = [], action) => {
  switch (action.type) {
    case "GET_PROG_DET":
      return action.payload.data;
    case "CLEAR_PROG_DET":
      return [];
    default:
      return state;
  }
};

export const getQPDeta = (state = [], action) => {
  switch (action.type) {
    case "GET_DET_QP_DATA":
      return action.payload.data;
    case "CLEAR_DET_QP_DATA":
      return [];
    default:
      return state;
  }
};

export const getFinQPData = (state = [], action) => {
  switch (action.type) {
    case "GET_FINAL_QP_DATA":
      return action.payload.data;
    case "CLEAR_FINAL_QP_DATA":
      return [];
    default:
      return state;
  }
};

export const getFinalQPAns = (state = [], action) => {
  switch (action.type) {
    case "GET_FINAL_QP_ANS":
      return action.payload.data;
    case "CLEAR_FINAL_QP_ANS":
      return [];
    default:
      return state;
  }
};

export const getSysPatrDet = (state = [], action) => {
  switch (action.type) {
    case "GET_SYS_PATR_DET":
      return action.payload.data;
    case "CLEAR_SYS_PATR_DET":
      return [];
    default:
      return state;
  }
};

export const getAvailableQp = (state = [], action) => {
  switch (action.type) {
    case "GET_AVL_QP_SUM":
      return action.payload.data;
    case "CLEAR_AVL_QP_SUM":
      return [];
    default:
      return state;
  }
};

export const getDetailedAvailableQpData = (state = [], action) => {
  switch (action.type) {
    case "GET_AVL_QP_DET":
      return action.payload.data;
    case "CLEAR_AVL_QP_DET":
      return [];
    default:
      return state;
  }
};

export const checkTemplateCode = (state = [], action) => {
  switch (action.type) {
    case "QP_TEMPLATE_NOT_FOUND":
      return action.payload;
    case "QP_TEMPLATE_FOUND":
      return action.payload;
    default:
      return state;
  }
};

export const getAvailableQpDetails = (state = [], action) => {
  switch (action.type) {
    case "AVAILABLE_QP_DETAILS":
      return action.payload;
    case "CLEAR_AVAILABLE_QP_DETAILS":
      return action.payload;
    default:
      return state;
  }
};

export const getQPBoardSubj = (state = [], action) => {
  switch (action.type) {
    case "GET_BOARD_AVL_SUBJ":
      return action.payload.data;
    case "CLEAR_BOARD_AVL_SUBJ":
      return [];
    default:
      return state;
  }
};
