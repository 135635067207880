export const getAllCollDetial = (state = [], action) => {
  switch (action.type) {
    case "ALL_COLL_DETAILS":
      return action.payload.data;
    case "CLEAR_COLL_DETAILS":
      return [];
    default:
      return state;
  }
};

export const getAllDegreeDet = (state = [], action) => {
  switch (action.type) {
    case "GET_COLL_TOT":
      return action.payload.data;

    case "CLEAR_COLL_TOT":
      return [];
    default:
      return state;
  }
};

export const getAllCollDet = (state = [], action) => {
  switch (action.type) {
    case "GET_COLL_DET":
      return action.payload.data;
    case "CLEAR_COLL_DET":
      return [];
    default:
      return state;
  }
};

export const getDegreeNam = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_NAM":
      return action.payload.data;
    case "CLEAR_COLL_NAM":
      return [];
    default:
      return state;
  }
};

export const getSelDegrSem = (state = [], action) => {
  switch (action.type) {
    case "GET_SEL_SEM":
      return action.payload.data;
    case "CLEAR_SEL_SEM":
      return [];
    default:
      return state;
  }
};

export const getSelSem = (state = [], action) => {
  switch (action.type) {
    case "GET_SEL_SEM_DET":
      return action.payload.data;
    case "CLEAR_SEL_SEM_DET":
      return [];
    default:
      return state;
  }
};

export const viewStd = (state = [], action) => {
  switch (action.type) {
    case "GET_VIEW_STD_DET":
      return action.payload.data;
    case "CLEAR_VIEW_STD_DET":
      return [];
    default:
      return state;
  }
};

export const getComStdDet = (state = [], action) => {
  switch (action.type) {
    case "GET_COM_STD_DET":
      return action.payload.data;
    case "CLEAR_COM_STD_DET":
      return [];
    default:
      return state;
  }
};

export const currActive = (state = [], action) => {
  switch (action.type) {
    case "CURRENT_ACTIVE":
      return action.payload.data;
    case "CLEAR_CURRENT_ACTIVE":
      return [];
    default:
      return state;
  }
};

export const getHistory = (state = [], action) => {
  switch (action.type) {
    case "GET_HISTORY_COUNT":
      return action.payload;
    case "CLEAR_HISTORY_COUNT":
      return [];
    default:
      return state;
  }
};

export const getStudDelHistory = (state = [], action) => {
  switch (action.type) {
    case "DELETE_HISTORY_COUNT":
      return action.payload;
    case "CLEAR_DELETE_HISTORY_COUNT":
      return [];
    default:
      return state;
  }
};
