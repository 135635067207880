const initstate = {
  fname: "",
  regno: "",
  degree: "",
  collname: "",
  fappno: "",
  fcatcode: "",
  freccer: "",
  fnational: "",
  fmobile: "",
  famount: "",
  fpaytype: "",
  fpaydate: "",
  ftranid: "",
  postadd1: "",
  postadd2: "",
  city: "",
  state: "",
  pincode: "",
  contry: "",
  aadahr: "",
  fdob: "",
  femail: ""
};

export const userTypeList = (state = [], action) => {
  switch (action.type) {
    case "USER_TYPE_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

export const convoDet = (state = [], action) => {
  switch (action.type) {
    case "CONVO_DETAILS":
      return action.payload.data;

    case "CHANGE_CONVO_DETAILS":
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case "CONVO_CLEAR":
      return initstate;
    default:
      return state;
  }
};
