export const mcfverifynew = (state = [], action) => {
  switch (action.type) {
    case "MCF_VERIFY_NEW":
      return action.payload.data;
    case "CLEAR_MCF_VERIFY_NEW":
      return [];
    default:
      return state;
  }
};
export const mcfview = (state = [], action) => {
  switch (action.type) {
    case "MCF_VIEW":
      return action.payload.data;
    case "CLEAR_MCF_VIEW":
      return [];
    case "CHECK_STATUS":
      const newarr = state.map((el, i) => {
        if (i === action.payload.ind) {
          if (action.payload.cond) {
            el.fverifystatus = "T";
          } else {
            el.fverifystatus = "F";
          }
        }
        return el;
      });
      return [...newarr];
    case "CHECK_REMARKS":
      const newarr1 = state.map((el, i) => {
        if (i === action.payload.ind) {
          el.fverifyremarks = action.payload.cond;
        }
        return el;
      });
      return [...newarr1];
    default:
      return state;
  }
};
