const fetchPrSubReduces = (state = [], action) => {
  switch (action.type) {
    case "FETCH_PRSUB":
      return action.payload.data;
    default:
      return state;
  }
};

const fetchBoardReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_BOARDS":
      return action.payload.data;
    default:
      return state;
  }
};

const fetchPrBatchReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_PRBATCHDET":
      return action.payload.data;
    default:
      return state;
  }
};

export { fetchPrSubReduces, fetchBoardReducer, fetchPrBatchReducer };

export const getCollegeDet = (state = [], action) => {
  switch (action.type) {
    case "GET_PR_COLLEGE":
      return action.payload.data;
    case "CLEAR_PR_COLLEGE":
      return [];
    default:
      return state;
  }
};

export const getDegreeColl = (state = [], action) => {
  switch (action.type) {
    case "GET_PR_DEGREE":
      return action.payload.data;
    case "CLEAR_PR_DEGREE":
      return [];
    default:
      return state;
  }
};

export const getSemDeg = (state = [], action) => {
  switch (action.type) {
    case "GET_PR_SEM":
      return action.payload.data;
    case "CLEAR_PR_SEM":
      return [];
    default:
      return state;
  }
};

export const getSubjDeg = (state = [], action) => {
  switch (action.type) {
    case "GET_PR_SUBJ":
      return action.payload.data;
    case "CLEAR_PR_SUBJ":
      return [];
    default:
      return state;
  }
};

export const getBatchSubj = (state = [], action) => {
  switch (action.type) {
    case "GET_PR_BATCH":
      return action.payload.data;
    case "CLEAR_PR_BATCH":
      return [];
    default:
      return state;
  }
};

export const viewPracticalMarks = (state = [], action) => {
  switch (action.type) {
    case "GET_PR_VIEW":
      return action.payload.data;
    case "CLEAR_PR_VIEW":
      return [];
    default:
      return state;
  }
};
