export const loadexamappcentre = (state = [], action) => {
  switch (action.type) {
    case "GET_EXM_CENTRE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
export const loadexamappdegree = (state = [], action) => {
  switch (action.type) {
    case "GET_EXM_DEGREE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const loadexamappsem = (state = [], action) => {
  switch (action.type) {
    case "GET_EXM_SEM_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const loadexamappdetails = (state = [], action) => {
  switch (action.type) {
    case "GET_EXM_DET_DATA":
      return action.payload.data;
    case "CLEAR_EXM_DET_DATA":
      return [];
    default:
      return state;
  }
};
