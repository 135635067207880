import { StepDescription } from "semantic-ui-react";

const initstate = {
  groups: [],
  head: { ftitle: "" },
  msgs: [],
  users: [],
  members: []
};

export const messageBoard = (state = initstate, action) => {
  switch (action.type) {
    case "GET_MSG_GROUPS":
      return { ...state, groups: action.payload.data.groups };
    case "GET_MSGS_FOR_GROUP":
      return {
        ...state,
        head: action.payload.data.head,
        msgs: action.payload.data.msgs,
        users: action.payload.data.users
      };
    case "GET_USERS_FOR_GROUP":
      return {
        ...state,
        members: action.payload.data
      };
    case "EMPTY_USERS_FOR_GROUP":
      return {
        ...state,
        members: []
      };
    case "UPDATE_MESSAGE":
      return { ...state, msgs: [action.payload, ...state.msgs] };
    default:
      return state;
  }
};

const initstateEdit = {
  head: {
    mode: "",
    fgroupid: "",
    ftitle: "",
    fdescpn: "",
    fexit: "",
    fexpdate: "",
    fsuspend: "",
    ftwoway: "",
    fcreatedby: ""
  }
};

export const groupEdit = (state = initstateEdit, action) => {
  switch (action.type) {
    case "GET_GROUP_HEAD":
      return { head: action.payload.data };
    case "CHANGE_GROUP_HEAD":
      const { name, value } = action.payload;
      return { head: { ...state.head, [name]: value } };
    case "CANCEL_GROUP_HEAD":
      return initstateEdit;
    default:
      return state;
  }
};
