export const getadmdegree = (state = [], action) => {
  switch (action.type) {
    case "GET_ADMDEGREE":
      return action.payload.data;
    case "CLEAR_GET_ADMDEGREE":
      return [];
    default:
      return state;
  }
};

export const getadmcollege = (state = [], action) => {
  switch (action.type) {
    case "GET_ADMCOLLEGE":
      return action.payload.data;
    case "CLEAR_GET_ADMCOLLEGE":
      return [];
    default:
      return state;
  }
};

export const getadmcomb = (state = [], action) => {
  switch (action.type) {
    case "GET_ADMCOMBINATION":
      return action.payload.data;
    case "CLEAR_GET_ADMCOMBINATION":
      return [];
    default:
      return state;
  }
};

export const viewadmapprove = (state = [], action) => {
  switch (action.type) {
    case "VIEW_ADMAPPROVE":
      return action.payload.data;
    case "CLEAR_VIEW_ADMAPPROVE":
      return [];
    default:
      return state;
  }
};

export const getadmcombreport = (state = [], action) => {
  switch (action.type) {
    case "GET_ADMCOMBINATION_REPORT":
      return action.payload.data;
    case "CLEAR_GET_ADMCOMBINATION_REPORT":
      return [];
    default:
      return state;
  }
};
