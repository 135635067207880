export const getstuddetforrt = (state = [], action) => {
  switch (action.type) {
    case "RT_MARKS_ENTRY":
      return action.payload.data;
    default:
      return state;
  }
};

export const getExamDet = (state = [], action) => {
  switch (action.type) {
    case "RT_MARKS_EXAM_DET":
      return action.payload.data;
    default:
      return state;
  }
};

export const getDegrDet = (state = [], action) => {
  switch (action.type) {
    case "RT_MARKS_DEGREE_DET":
      return action.payload.data;
    default:
      return state;
  }
};

export const getSemDet = (state = [], action) => {
  switch (action.type) {
    case "RT_MARKS_SEM_DET":
      return action.payload.data;
    default:
      return state;
  }
};
