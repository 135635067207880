const initstate = [];
const examno_initstate = {
  exmno: [],
  masledg: [],
};

export const setviewledger = (state = initstate, action) => {
  switch (action.type) {
    case "GET_VIEW_LEDGER":
      return action.payload.data;
    case "CLEAR_VIEW_LEDGER":
      state = undefined;
      return initstate;
    default:
      return state;
  }
};

export const getviewledgexamno = (state = examno_initstate, action) => {
  switch (action.type) {
    case "VIEW_LEDGER_EXAMNO":
      return action.payload.data;
    default:
      return state;
  }
};
