const initstate = [];
const chairman_brd = { screen: [], res: [], fboard: "", fgrpname: "" };

export const getcreateBORreducer = (state = initstate, action) => {
  switch (action.type) {
    case "GET_BOE_TABLE":
      return action.payload.data;
    case "CLEAR_CREATE_BOE":
      return initstate;
    default:
      return state;
  }
};

export const boechrboard = (state = chairman_brd, action) => {
  switch (action.type) {
    case "CHAIRMAN_BOARD":
      if (action.payload.data.screen == "edit") {
        return {
          screen: action.payload.data.screen,
          res: action.payload.data.res,
          fboard: action.payload.data.res[0].fboard,
          fgrpname: action.payload.data.res[0].fgrpname,
        };
      }
      return {
        screen: action.payload.data.screen,
        res: action.payload.data.res,
        fboard: "",
        fgrpname: "",
      };
    case "CHANGE_BOE_GRPNAME":
      return { ...state, fgrpname: action.payload.data };
    case "CHANGE_BOE_BOARD":
      return { ...state, fboard: action.payload.data };
    default:
      return state;
  }
};

export const boeteachlist = (state = initstate, action) => {
  switch (action.type) {
    case "BOE_TEACHERS":
      if (action.payload.data.screen == "create") {
        let boe_teach = [];
        action.payload.data.res.map((item) => {
          boe_teach.push({
            fcollcode: item.fcollcode,
            fteachcode: item.fteachcode,
            fteachname: item.fteachname,
            fscale: item.fscale,
            fmobile: item.fmobile,
            fdeleted: "T",
          });
        });
        return boe_teach;
      } else {
        return action.payload.data.res;
      }
    case "CHANGE_TEACHLIST_BOE":
      const chgteach = state.map((el, i) => {
        if (action.payload.i == i) {
          el.fdeleted = action.payload.data;
          return el;
        } else {
          return el;
        }
      });
      return [...chgteach];
    case "CLEAR_CREATE_BOE":
      return initstate;
    default:
      return state;
  }
};
