import { combineReducers } from "redux";
import curunivReducer from "./curUniv";
import univReducer from "./univs";
import dashboardReducer from "./dashboard";
import { degGrpReducer, degGrpDegreeReducer } from "./masters/deggrp";
import degreesReducer from "./masters/degrees";
import combinationReducer from "./masters/combination";
import saveDeggrpReducer from "./masters/saveDeggrp";
import cntrListReducer from "./before-exam/cntrList";
import { examCntrDetReducer, degCollReducer } from "./before-exam/examCntrDet";
import studentInfoReducer from "./masters/studentInfo";
import collegeReducer from "./masters/college";
import dateMaster from "./masters/datemaster";
import activeCollegeReducer from "./masters/getactiveclglist";
import { refundReducer } from "./finance/refundListReducer"; //"./refundListReducer";
import holidayMaster from "./masters/holidayMaster"; //"./holidayMaster";
import creditMaster from "./masters/creditMaster"; //"./creditMaster";
import {
  getTimeTablesReducer,
  getdegreesttm,
} from "./before-exam/viewtimetable"; //"./viewtimetable";
import { sendSmsReducer } from "./after-exam/sendSmsRed"; //"./sendSmsRed";
import { userTypeList } from "./utils/userTypeList";
import {
  getDegSemList,
  getDegSemEdit,
  getDegClassEdit,
  getDegReason,
} from "./masters/degSemList";
import { grpCreation, grpFormData, getDeg } from "./utils/groupCreation";
import { inwardCreation } from "./utils/inword";
import { outwardCreation } from "./utils/outward";
import {
  loadingReducer,
  setErrorReducer,
  showErrorReducer,
} from "./appDefaults";
import {
  getValInviReducer,
  sendMsgAll,
} from "./after-exam/valuationInvitationRed"; //"./valuationInvitationRed";
import {
  timetableReduces,
  reasonReduces,
  masdateReduces,
} from "./before-exam/genaratett"; //"./genaratett";
import { grvlstReducer, grvReducer, grvSntReducer } from "./utils/grv"; //"./grv";
import {
  fetchPrSubReduces,
  fetchBoardReducer,
  fetchPrBatchReducer,
  getCollegeDet,
  getDegreeColl,
  getSemDeg,
  getSubjDeg,
  getBatchSubj,
  viewPracticalMarks,
} from "./practicals/practicals";
import { getExmCntrRedu } from "./before-exam/exmcntr"; //"./exmcntr";
import {
  loginReducer,
  userReducer,
  userTypeReducer,
  menuReducer,
  regnReducer,
  fgtReducer,
  getSubMenus,
} from "./registration/loginRegn"; //"./loginRegn";
import { fetchHallTicketReducer } from "./before-exam/hallTicket"; //"./hallTicket";
import { bundleRecvReducer } from "./during-exam/bundrecv"; //"./bundrecv";
import {
  lstDateUpdateRedu,
  getCollegesAsperDeggrp,
  getDegreesAsperDeggrp,
} from "./utils/lastDateUpdate"; //"./lastDateUpdate";
import {
  feeHeadsReducer,
  categoryReducer,
  feeStrFormReducer,
  feeDetlReducer,
  feeDateReducer,
} from "./finance/fee-str"; //"./fee-str";
import { uploadReducer } from "./utils/upload"; //"./upload";
import {
  qpStatReducer,
  qpStatSumReducer,
  getTeachCodeRange,
  getfbaordName,
  getTeachCodeOnBoard,
} from "./after-exam/valuation"; //"./valuation";
import { pgetEditApp, pgetDeg } from "./utils/pget"; //"./pget";
import { CollegeForm } from "./masters/collegeform"; //"./collegeform";
// kunal
import { resultStatCollegeWise } from "./after-exam/collegewisestat"; //"./collegewisestat";
import {
  allClgList,
  GetSpecificCollege,
  GetDeggrpOnCollege,
  getDegreeOnCol,
  getIaShortUpdData,
} from "./masters/collegeformlist"; //"./collegeformlist";
import { studentDegreeCombintaion } from "./admissions/admission"; //"./admission";
import { ExamAppStats, ExmDetStats } from "./before-exam/exmAppStats"; //"./exmAppStats";
import { getDegExmRng, shwFeeUpdateDet } from "./after-exam/RVRTFeeUpdate"; //"./RVRTFeeUpdate";
import { getAdmStats, getSpecCollDet } from "./admissions/admStats"; //"./admStats";
// import { allClgList } from "./allclglist";
import { getFaculty } from "./masters/faculty"; //"./faculty";
import { getDegreeDet } from "./masters/degreeEntryScrn"; //"./degreeEntryScrn";
import cropImage, {
  collegeWiseDegreeList,
  getStudentPhotosDegreeWise,
} from "./utils/cropimage"; //"./cropimage";
import { userDetails, viewCreateUser } from "./utils/createUser"; //"./createUser";
import { menuLists } from "./loadMenuList";
import { getExmNo, getSubDet, getSubData } from "./masters/subjectEntrySrcn"; //"./subjectEntrySrcn";
import { getBoard } from "./masters/boards"; //"./boards";
import { getReasonsID } from "./masters/reasonMaster";
import {
  boardList,
  getTeachLists,
  getBoardEditLists,
  getChairmanCode,
  getChairmanDetails,
  getChairmanCodeDetailsMass,
} from "./masters/boardMaster";
import { getMasQP } from "./masters/masQP";
import { taskReminder, taskReminderLIST } from "./utils/taskreminder";
import { holidayList } from "./masters/holidaylist";
import { postNotifList, editNotification } from "./utils/hostNotification";
import {
  smsReducer,
  viewSMSReducer,
  viewNotifReducer,
  getCollegeDetails,
  getDegrees,
  getDegreefromDeggrp,
  getColleges,
} from "./utils/sms-notification";
import { saveValInvi } from "./after-exam/saveValInviRed";
import { workDoneReport } from "./after-exam/getallworkdone";
import { workDoneReportSummary } from "./after-exam/getworkdoneSummary";
import { saveInvitation, sendMsg } from "./after-exam/valuationInvitationRed";
import { saveInviteList } from "./after-exam/valuationInvitationRed";
import { NotificationList } from "./utils/getNotifications";
import {
  dailyValuation,
  getfboarddailyval,
  getdegAll,
  getCollegeOnDeggrp,
  getDegreeOnDeggrpCollege,
} from "./after-exam/dailyValuation";
import { dailyRvPcReport } from "./after-exam/dailyRvPcReport";
import { saveRvRtCheckList } from "./after-exam/saveRvRtCheckList";
import {
  GetNotVal,
  fetchDropNotValued,
  getCollegenotVal,
} from "./after-exam/getnotVal";
import { fetchDropDownOptions } from "./finance/getDropDownOptionsReducer";

import { messageBoard, groupEdit } from "./utils/MessageBoard_RED";
import { convoDet } from "./convocation/convocation";
import { convoRecDet } from "./convocation/convorec";
import {
  studyDegreeListReducer,
  studyExamnoReducer,
  studySubjectReducer,
  studyUploadDocReducer,
  getstudyMaterialReducer,
} from "./studymaterial/studyMaterial";
import { subtabledatareducer } from "./subjectmapping/subjectmapping";
import {
  resultstatsreportdeglist,
  resultstatsreportclglist,
  restatloaddeg,
  restatloadcoll,
} from "./resultstatsreport/resultstatsrptreducer";
import {
  setpracticalexamcenter,
  getcollegelistpractical,
  getsubjectlistpractical,
  getcentrecodepractical,
} from "./setpracticalcenter/setpracticalcenterreducer";
import { getfreezetimetablereducer } from "./before-exam/freezetimetable";
import { getreleasetimetablereducer } from "./before-exam/releasetimetable";
import {
  dispmarkscard,
  dispcollegelist,
  dispmarkscardclg,
  dispdetails,
  dispdetailsclg,
} from "./dispatchmarkscard/dispatchmarkscardreducer";
import { getRVMarksEntry, getdocdetRVreducer } from "./after-exam/rvMarksEntry";
import {
  setviewledger,
  getviewledgexamno,
} from "./viewledger/viewledgerreducer";
import {
  getcreateBORreducer,
  boechrboard,
  boeteachlist,
} from "./create-boe/boereducer";
import { getTeacherId } from "./changecollege/teacher";
import { getvalheads } from "./val_center/val_cntr";
import { updatevalcenter } from "./update_val_center";
import { getdegreevalcenter } from "./update_val_center";
import { mcfverifynew } from "./mcfverifynew/mcf_new";
import { mcfview } from "./mcfverifynew/mcf_new";
import { getinwordstatus } from "./inword_update";
import { viewinword } from "./utils/inword.js";
import {
  getstuddetforrt,
  getExamDet,
  getDegrDet,
  getSemDet,
} from "./rt_marks_entry";
import {
  getStudNameChange,
  getTeachIdData,
  getExamDateval,
} from "./approvestudname";
import { saveStudNameChange } from "./approvestudname";
import { uploaddoccorr } from "./uplcorrdoc";
import { viewuploaddoccorr, regcodeverification } from "./uplcorrdoc";
import {
  deggrpexamcentre,
  allexamcentre,
  dateall,
  loadexamcentretable,
  loadmastersdeg,
  loadsubrptsem,
} from "./viewexamcentrenew";
import { getmarksentrydet, updatemarksentrydet } from "./marksentry";
import { loadunivall, loadmodcorr } from "./marksmodreport";

import { studentadmission } from "./masters/studadm";
import {
  getBundledeggrp,
  getExamDate,
  getbundledispatch,
} from "./bundledispatch";

import { generateDegreeDetails } from "./absent/mp_report";
import {
  getrvrtdeggrp,
  getrvrtexamno,
  getrvrtqpcode,
  getrvrttable,
} from "./rvrtblock";
import {
  fetchBoardDet,
  getTCode,
  getCollCode,
  getCollCodefrmdeggrp,
} from "./after-exam/valuationBill";

import { viewteachattendcollege, viewteachattenddet } from "./viewteachattend";
import { viewbackup, getUplaodBackUpUnivs } from "./viewbackup";
// import { viewbackup } from "./viewbackup";
import {
  getadmdegree,
  viewadmapprove,
  getadmcollege,
  getadmcomb,
  getadmcombreport,
} from "./admapprove";

import {
  getexamappdegree,
  getexamappcollege,
  getexamappexamno,
} from "./examapprpt";

import {
  getcollegereport,
  getdegreereport,
  submitcollegereport,
  loaddegreegroupclgrpt,
  getCollAbsentReport,
} from "./collegereport";

import { getcentrereport } from "./centrereports";

import {
  getTdvsBoardDetails,
  getTdvsQPDetails,
  getTDVSQpPdfDet,
  getTDVSStudentDeatils,
  getTdvsReport,
  getDecodingData,
  getDecodeDiff,
  saveUpdatedRegAnsNo,
  getTdvsCountData,
  getUUCMSExmDate,
  getUUCMSQPC,
  getUUCMSData,
  getDataQP,
  uucmsPacketNO,
  upldUUCMS,
  getErrorCode,
} from "./tdvsdecoding/tdvsdecoding";

import {
  getAllCollDetial,
  getAllDegreeDet,
  getDegreeNam,
  getSelDegrSem,
  getAllCollDet,
  getSelSem,
  viewStd,
  getComStdDet,
  currActive,
  getHistory,
  getStudDelHistory,
} from "./Dashboard/index";

import {
  getBoardDet,
  getBoardSubj,
  getTeachDet,
  getSelTeachDet,
  getQPDetails,
  displayQPDetails,
  disAppQPDetails,
  examDate,
  getBankDet,
  acceptQPDet,
  acceptedUploadQP,
  getFinalQpData,
  getTeacherAllData,
  submitQPDet,
  getProgramDet,
  getQPDeta,
  getFinQPData,
  getFinalQPAns,
  getSysPatrDet,
  getAvailableQp,
  getDetailedAvailableQpData,
  checkTemplateCode,
  getAvailableQpDetails,
  getQPBoardSubj,
} from "./QP_Setter/index";

import {
  getAttColl,
  getAttDegree,
  getSemDegr,
  getSecSem,
  viewAttSet,
} from "./Attendance_report";

import { viewAtteBlock } from "./attendanceblock";
import { degDet, collDet, boardDet, teachDet, getTeach } from "./master/degree";
import {
  getDegrGrp,
  scriptBoard,
  qpRecjDet,
  getRejScrCheck,
} from "./tdvs_recj_script_check";
import {
  getTeacherDesignation,
  getTeachBoardDetails,
  getQPSubjects,
  viewTeacherEntry,
  getTeacherData,
  loadYearAndMode,
  assignModeratorDet,
  qpModeratorSummary,
  viewQuestionPaper,
  viewQPPatternHandler,
  viewFinalQp,
  getModeratorData,
  getModUpdData,
  selectFinalQp,
  getModeratorDet,
  getModQPData,
} from "./teacher/teachersentry";
import { viewBoardMaster } from "./boardmaster";
import {
  getregcodeyear,
  getregmode,
  getregqpcode,
  getregpacket,
  viewsearchregcode,
} from "./searchregcode/searchregcode";

import {
  getcollegeiamarks,
  getdegreeia,
  getsemia,
  getsubjectia,
  viewiamarkstable,
} from "./iamarkssummary";

import { getstudentregistrtionlist } from "./std_regsitrtion/std_registrtion";

import { getdgrgroupdet } from "./pushmarks";

import {
  getdatedeg,
  getdatesem,
  getdatesub,
  getdatelement,
  viewdatesentry,
  getMaxTest,
} from "./dateupdate";
import {
  loadQPDet,
  totUploadAnsBookDet,
  getYearModeData,
} from "./ans-book-mang/uploadAnsBook";

import {
  getResStudDegree,
  getResStudSem,
  getResStudSubject,
  getResStudData,
} from "./stud_ack/stud_ack";

import {
  setDeggrp,
  getExamMode,
  getDegree,
} from "../reducers/acknowlege_direct";

import { getIAMarksSub } from "./updateIAMarks/index";
import { headsdetails } from "./define_heads/index";

import {
  getprogramedet,
  getDatesEntryForm,
  loadeditingDet,
  loaddegreeheads,
  loadeditdet,
  getVerfyData,
  viewNotifdet,
} from "./Notification_Entry/index";

// import { getExamDateDet, getDeggrpDet, getMarksDet } from "./certification";
import {
  oldquestionpaperdegree,
  oldquestionpaperexamno,
  oldquestionpaperexamdate,
  getoldqpdet,
} from "./oldquestionpaper";
import { getAdmisionDegree } from "./admisiondegree/index";
import {
  getDeggrp,
  getExamDateDet,
  getDeggrpDet,
  getMarksDet,
} from "./certification";

import {
  loaddegreegrouptm,
  getdegreetmdet,
  getsemtmdet,
  getsubjtmdet,
  loadteacherinfotm,
  loadtableinfotm,
  loadergrpview,
  getdegreeviewdet,
  getsemviewdet,
  loadtableinfoview,
} from "./teacherEmail";
import {
  getQpSetterDetData,
  getUploadQPseterDetData,
  selectModerator,
  getQpSummaryNew,
  getDetaileQpSummary,
  getFinalQuestionPaperSumData,
  showFinalQPFinalDetdata,
} from "./qpsetterteacher/acceptQpRequest";

import {
  loadtdvssubject,
  loadtdvsdate,
  loadtdvsstats,
  loadqpdetails,
  loadalldata,
  loadtdvsdegree,
  loadtdvssubj,
} from "./tdvs_stats/index.js";

import {
  loadexamappcentre,
  loadexamappdegree,
  loadexamappsem,
  loadexamappdetails,
} from "./exam_app_acknowledge/index.js";

import {
  getNotificationDegree,
  getNotificationHeadDet,
  getNotificationHeads,
  getNotificationSem,
} from "./upcomingnotifications/upcomingNotifications.js";

import {
  getTeachData,
  getExamOrderDet,
  getOrderData,
} from "./send_order/index.js";
import {
  getOmrQpCode,
  getOmrYearMode,
  viewOmrMarksEnrtyDet,
  getOmrMCQQP,
  saveOmrQp,
  uploadOmrFileHandlers,
  getOmrQuestionPapers,
  getSelectedMCQFinalPapers,
  getQpDeggrp,
  getExamDetails,
} from "./omr/omrqnentry.js";
import {
  loaduniversities,
  loadtickets,
  loademploye,
  loadempl,
} from "./Tickets/index.js";
import {
  getdeggrpresult,
  getExamdateResult,
  getDegreeResult,
  getSemResult,
  viewResultDate,
} from "./resultdate/index.js";
import {
  getMpcDeggrp,
  getMpcExamDate,
  getMpcManageData,
} from "./mpcmanagement/mpcmanagement.js";
import {
  getDegreeGroupDegReducer,
  getRvDatesReducer,
  getRVStatsReducer,
  getRVQPDeatilsReducer,
} from "./rv_stats/rv_stats.js";
import {
  getPrepareThQpDetails,
  getPrepareQpSummaryDet,
  getTempHeadSection,
  getQuestions,
  saveQuestions,
  getModQPOnline,
} from "./prepareqp";

const reducers = combineReducers({
  user: userReducer,
  usertype: userTypeReducer,
  login: loginReducer,
  regn: regnReducer,
  fgtpasswd: fgtReducer,
  univ: curunivReducer,
  univs: univReducer,
  menu: menuReducer,
  dashboard: dashboardReducer,
  loading: loadingReducer,
  error: setErrorReducer,
  moderror: showErrorReducer,
  combination: combinationReducer,
  deggrp: degGrpReducer,
  degGrpDegree: degGrpDegreeReducer,
  degrees: degreesReducer,
  saveDeggrp: saveDeggrpReducer,
  cntrList: cntrListReducer,
  degcoll: degCollReducer,
  examCntrDet: examCntrDetReducer,
  studentInfo: studentInfoReducer,
  collegeReport: collegeReducer,
  dateMaster: dateMaster,
  holidayMaster: holidayMaster,
  creditMaster: creditMaster,
  timetable: timetableReduces,
  reason: reasonReduces,
  prsubs: fetchPrSubReduces,
  boards: fetchBoardReducer,
  prbatch: fetchPrBatchReducer,
  masdate: masdateReduces,
  grvlst: grvlstReducer,
  grv: grvReducer,
  grvSnt: grvSntReducer,
  getExmCntrs: getExmCntrRedu,
  HTCnt: fetchHallTicketReducer,
  bundleRecv: bundleRecvReducer,
  lastDU: lstDateUpdateRedu,
  feeheads: feeHeadsReducer,
  upload: uploadReducer,
  qpstat: qpStatReducer,
  qpstatsum: qpStatSumReducer,
  category: categoryReducer,
  feeStrForm: feeStrFormReducer,
  feeDetl: feeDetlReducer,
  feeDate: feeDateReducer,
  pgetEditApp: pgetEditApp,
  pgetDeg: pgetDeg,
  collegeList: allClgList,
  CollegeForm: CollegeForm,
  resultStatCollegeWise: resultStatCollegeWise,
  studentDegreeCombintaion: studentDegreeCombintaion,
  ExamAppStats: ExamAppStats,
  ExmDetStats: ExmDetStats,
  getDegExmRng: getDegExmRng,
  shwFeeUpdateDet: shwFeeUpdateDet,
  admCollStats: getAdmStats,
  getSpecCollDet: getSpecCollDet,
  allcollegeList: allClgList,
  cropImage: cropImage,
  collegeWiseDegreeList: collegeWiseDegreeList,
  getStudentPhotosDegreeWise: getStudentPhotosDegreeWise,
  getFaculty: getFaculty,
  getDegreeDet: getDegreeDet,
  activeCollege: activeCollegeReducer,
  refundList: refundReducer,
  GetSpecificCollege: GetSpecificCollege,
  userTypeList: userTypeList,
  userDetails: userDetails,
  menuList: menuLists,
  getExmNo: getExmNo,
  getSubjects: getSubDet,
  subjectData: getSubData,
  //getOldSubData: getOldSubData,
  board: getBoard,
  getReasonsIDs: getReasonsID,
  boardList: boardList,
  masQPDet: getMasQP,
  taskReminderData: taskReminder,
  ALLReminderTask: taskReminderLIST,
  allworkDOneReport: workDoneReport,
  allworkDoneReportSummary: workDoneReportSummary,
  holidayList: holidayList,
  postNotifList: postNotifList,
  editNotification: editNotification,
  viewTimeTable: getTimeTablesReducer,
  smsNotif: smsReducer,
  dailyValuation: dailyValuation,
  dailyRvPcReport: dailyRvPcReport,
  saveRvRtCheckList: saveRvRtCheckList,
  valInvitation: getValInviReducer,
  valInvi: saveValInvi,
  saveInvitation: saveInvitation,
  saveInviteList: saveInviteList,
  dashboardNotifList: NotificationList,
  sendSms: sendSmsReducer,
  sendMsg: sendMsg,
  getNotvalData: GetNotVal,
  fetchDropNotValued: fetchDropNotValued,
  sendMsgAll: sendMsgAll,
  fetchDropDownOptions: fetchDropDownOptions,
  getDegSemList: getDegSemList,
  degsem: getDegSemEdit,
  degcalss: getDegClassEdit,
  degreason: getDegReason,
  msgBoard: messageBoard,
  grpCreation: grpCreation,
  inwardCreation: inwardCreation,
  outwardCreation: outwardCreation,
  grpData: grpFormData,
  grpEdit: groupEdit,
  viewSMS: viewSMSReducer,
  viewNotif: viewNotifReducer,
  studyDegree: studyDegreeListReducer,
  studyExamno: studyExamnoReducer,
  studySubject: studySubjectReducer,
  studyUploadDoc: studyUploadDocReducer,
  getstudyMaterial: getstudyMaterialReducer,
  submapTabledata: subtabledatareducer,
  resultStatsDegList: resultstatsreportdeglist,
  resultStatsClgList: resultstatsreportclglist,
  practicalCenter: setpracticalexamcenter,
  freezeTimeTable: getfreezetimetablereducer,
  releaseTimeTable: getreleasetimetablereducer,
  dispatchMarkscard: dispmarkscard,
  dispCollegeList: dispcollegelist,
  dispatchMarkscardClg: dispmarkscardclg,
  dispatchDetails: dispdetails,
  dispatchDetailsCLG: dispdetailsclg,
  rvMarksEntry: getRVMarksEntry,
  rvDocDet: getdocdetRVreducer,
  convoDet: convoDet,
  convoRecDet: convoRecDet,
  viewledger: setviewledger,
  viewledgexamno: getviewledgexamno,
  boeCreate: getcreateBORreducer,
  boeChairmanBoard: boechrboard,
  boeteachlist: boeteachlist,
  degreettm: getdegreesttm,
  teachdet: getTeacherId,
  valheads: getvalheads,
  valuation: updatevalcenter,
  degreevalcenter: getdegreevalcenter,
  mcfverifynewdate: mcfverifynew,
  mcfviewdetail: mcfview,
  getinwordstatus: getinwordstatus,
  viewinword: viewinword,
  getstuddetforrt: getstuddetforrt,
  rtmarksexam: getExamDet,
  degrdet: getDegrDet,
  semdet: getSemDet,
  getStudNameChange: getStudNameChange,
  uploaddoccorr: uploaddoccorr,
  viewuploaddoccorr: viewuploaddoccorr,
  deggrpexamcentre: deggrpexamcentre,
  allexamcentre: allexamcentre,
  dateall: dateall,
  getmarksentrydet: getmarksentrydet,
  updatemarksentrydet: updatemarksentrydet,
  loadexamcentretable: loadexamcentretable,
  studentadmission: studentadmission,
  getBundledeggrp: getBundledeggrp,
  getExamDate: getExamDate,
  getbundledispatch: getbundledispatch,
  loadunivall: loadunivall,
  finalloadmodcorr: loadmodcorr,
  generateDegreeDetails: generateDegreeDetails,
  getrvrtdeggrp: getrvrtdeggrp,
  getrvrtexamno: getrvrtexamno,
  getrvrtqpcode: getrvrtqpcode,
  getrvrttable: getrvrttable,
  getcolldet: getCollegeDetails,
  getColl: getColleges,
  getdeg: getDegrees,
  getcoldeggrp: getCollegesAsperDeggrp,
  getdegdeggrp: getDegreesAsperDeggrp,
  getdegfromdeg: getDegreefromDeggrp,
  getcollegelistpractical: getcollegelistpractical,
  getsubjectlistpractical: getsubjectlistpractical,
  getcentrecodepractical: getcentrecodepractical,
  restatloaddeg: restatloaddeg,
  restatloadcoll: restatloadcoll,
  fetchBoardDetails: fetchBoardDet,
  tcode: getTCode,
  coldet: getCollCode,
  getcoldegdet: getCollCodefrmdeggrp,
  viewteachattendcollege: viewteachattendcollege,
  viewteachattenddet: viewteachattenddet,
  viewbackup: viewbackup,
  getUnivs: getUplaodBackUpUnivs,
  getadmdegree: getadmdegree,
  getadmcollege: getadmcollege,
  getadmcomb: getadmcomb,
  viewadmapprove: viewadmapprove,
  getadmcombreport: getadmcombreport,
  getTeachList: getTeachLists,
  getboardList: getBoardEditLists,
  getexamappcollege: getexamappcollege,
  getexamappdegree: getexamappdegree,
  getexamappexamno: getexamappexamno,
  collVal: getCollegenotVal,
  chairmanCode: getChairmanCode,
  chairmanDet: getChairmanDetails,
  teachRange: getTeachCodeRange,
  fboardDet: getfboarddailyval,
  fboardName: getfbaordName,
  teacode: getChairmanCodeDetailsMass,
  teachonboard: getTeachCodeOnBoard,
  getcollegereport: getcollegereport,
  getdegreereport: getdegreereport,
  submitcollegereport: submitcollegereport,
  getcentrereport: getcentrereport,
  loaddegreegroupclgrpt: loaddegreegroupclgrpt,
  loadmastersdeg: loadmastersdeg,
  loadsubrptsem: loadsubrptsem,
  tdvsBoard: getTdvsBoardDetails,
  qpDet: getTdvsQPDetails,
  tdvspdf: getTDVSQpPdfDet,
  tdvstud: getTDVSStudentDeatils,
  tdvsreport: getTdvsReport,
  getcolldet: getCollegeDet,
  getdegreecoll: getDegreeColl,
  getsubj: getSubjDeg,
  getsem: getSemDeg,
  getbatch: getBatchSubj,
  viewdata: viewPracticalMarks,
  // degrall: getAllDegree,
  // collcount: getAllCollCount,
  alldegdet: getAllDegreeDet,
  degrnam: getDegreeNam,
  selsem: getSelDegrSem,
  // subjlm: getSubj,
  // getTeach: getTeachCount,
  getAllCollDetial: getAllCollDetial,
  // getStd: getStdCount,
  colldet: getAllCollDet,
  subsem: getSelSem,
  lmtstddet: viewStd,
  comstddet: getComStdDet,
  boaddet: getBoardDet,
  boardsubj: getBoardSubj,
  teachdet: getTeachDet,
  seltech: getSelTeachDet,
  attcoll: getAttColl,
  attdegree: getAttDegree,
  attsem: getSemDegr,
  attsec: getSecSem,
  attview: viewAttSet,
  viewblock: viewAtteBlock,
  teacher: getTeachIdData,
  submenu: getSubMenus,
  getseldeg: degDet,
  qPaperDet: getQPDetails,
  disQPD: displayQPDetails,
  appDisQpD: disAppQPDetails,
  regcodeverify: regcodeverification,
  deggrpscrp: getDegrGrp,
  scriptBoard: scriptBoard,
  qpRecj: qpRecjDet,
  tabRejScr: getRejScrCheck,
  teachDesignation: getTeacherDesignation,
  viewBoardMaster: viewBoardMaster,
  teachBoard: getTeachBoardDetails,
  teachBoardDet: getQPSubjects,
  viewTeacherEntry: viewTeacherEntry,
  getTeacherData: getTeacherData,
  examdate: examDate,
  loadYearAndMode: loadYearAndMode,
  assignModeratorDet: assignModeratorDet,
  bankDet: getBankDet,
  accQPDet: acceptQPDet,
  accUploadDet: acceptedUploadQP,
  qpModeratorSummary: qpModeratorSummary,
  viewQuestionPaper: viewQuestionPaper,
  viewQPPattern: viewQPPatternHandler,
  getregcodeyear: getregcodeyear,
  getregmode: getregmode,
  getregqpcode: getregqpcode,
  getregpacket: getregpacket,
  viewsearchregcode: viewsearchregcode,
  viewFinalQp: viewFinalQp,
  getModeratorData: getModeratorData,
  getModUpdData: getModUpdData,
  getcollegeiamarks: getcollegeiamarks,
  getdegreeia: getdegreeia,
  getsemia: getsemia,
  getsubjectia: getsubjectia,
  viewiamarkstable: viewiamarkstable,
  getstudentregistrtionlist: getstudentregistrtionlist,
  viewCreateUser: viewCreateUser,
  selectFinalQp: selectFinalQp,
  getFinalQpData: getFinalQpData,
  colldetails: collDet,
  boardDetails: boardDet,
  teachdetails: teachDet,
  displayTeachDet: getTeach,
  currAct: currActive,
  viewCreateUser: viewCreateUser,
  selectFinalQp: selectFinalQp,
  getFinalQpData: getFinalQpData,
  colldetails: collDet,
  boardDetails: boardDet,
  teachdetails: teachDet,
  displayTeachDet: getTeach,
  currAct: currActive,
  viewCreateUser: viewCreateUser,
  selectFinalQp: selectFinalQp,
  getFinalQpData: getFinalQpData,
  getdgrgroupdet: getdgrgroupdet,
  getdatedeg: getdatedeg,
  getdatesem: getdatesem,
  getdatesub: getdatesub,
  getdatelement: getdatelement,
  viewdatesentry: viewdatesentry,
  modteach: getTeacherAllData,
  resQPDet: loadQPDet,
  totansup: totUploadAnsBookDet,
  decodedata: getDecodingData,
  resdegree: getResStudDegree,
  resSem: getResStudSem,
  resSub: getResStudSubject,
  resdata: getResStudData,
  getDecodeDiffDet: getDecodeDiff,
  setDeggrp: setDeggrp,
  setExamMode: getExamMode,
  setDegree: getDegree,
  saveDecodeDif: saveUpdatedRegAnsNo,
  decodeCount: getTdvsCountData,
  getIAMarksSub: getIAMarksSub,
  getDatesEntryForm: getDatesEntryForm,
  getStudHistory: getHistory,
  headsdetails: headsdetails,
  getprogramedet: getprogramedet,
  loadeditingDet: loadeditingDet,
  loaddegreeheads: loaddegreeheads,
  loadeditdet: loadeditdet,
  degdeggp: getDeggrp,
  examDateDet: getExamDateDet,
  deggrpdet: getDeggrpDet,
  getMarksStatus: getMarksDet,
  oldQpDegree: oldquestionpaperdegree,
  oldQpExamno: oldquestionpaperexamno,
  oldQpExamDate: oldquestionpaperexamdate,
  getOldQP: getoldqpdet,
  getAdmisionDegree: getAdmisionDegree,
  getVerfyData: getVerfyData,
  loaddegreegrouptm: loaddegreegrouptm,
  getdegreetmdet: getdegreetmdet,
  getsemtmdet: getsemtmdet,
  getsubjtmdet: getsubjtmdet,
  loadteacherinfotm: loadteacherinfotm,
  loadtableinfotm: loadtableinfotm,
  acceptQp: getQpSetterDetData,
  prepareQPData: getUploadQPseterDetData,
  qpdata: submitQPDet,
  getModData: selectModerator,
  loadergrpview: loadergrpview,
  getdegreeviewdet: getdegreeviewdet,
  getsemviewdet: getsemviewdet,
  loadtableinfoview: loadtableinfoview,
  getQpSumData: getQpSummaryNew,
  getQpDetData: getDetaileQpSummary,
  sumFinalQpData: getFinalQuestionPaperSumData,
  detFinalQpData: showFinalQPFinalDetdata,
  progDet: getProgramDet,
  dataQPDet: getQPDeta,
  modeData: getModeratorDet,
  getModQPData: getModQPData,
  finQPDat: getFinQPData,
  getFinalQPAns: getFinalQPAns,
  dataSysPatr: getSysPatrDet,
  viewNotifdet: viewNotifdet,
  loadtdvssubject: loadtdvssubject,
  loadalldata: loadalldata,
  loadtdvsdate: loadtdvsdate,
  loadtdvsstats: loadtdvsstats,
  getDeg: getDeg,
  loadqpdetails: loadqpdetails,
  loadexamappcentre: loadexamappcentre,
  loadexamappdegree: loadexamappdegree,
  loadexamappsem: loadexamappsem,
  loadexamappdetails: loadexamappdetails,
  loadtdvsdegree: loadtdvsdegree,
  loadtdvssubj: loadtdvssubj,
  allDetailDeg: getdegAll,
  getNotificationHeads: getNotificationHeads,
  getNotificationDegree: getNotificationDegree,
  getNotificationSem: getNotificationSem,
  getNotificationHeadDet: getNotificationHeadDet,
  getabsReport: getCollAbsentReport,
  colDeggrp: GetDeggrpOnCollege,
  colDegree: getDegreeOnCol,
  getIaAttData: getIaShortUpdData,
  getTest: getMaxTest,
  getAvlQP: getAvailableQp,
  getAvlQPDet: getDetailedAvailableQpData,
  teachList: getTeachData,
  getOrderTeach: getExamOrderDet,
  getOrderData: getOrderData,
  deletStdCut: getStudDelHistory,
  yerModeData: getYearModeData,
  getOmrQpCode: getOmrQpCode,
  getOmrYearMode: getOmrYearMode,
  viewOmrMarksEnrtyDet: viewOmrMarksEnrtyDet,
  getOmrMCQQP: getOmrMCQQP,
  saveOmrQp: saveOmrQp,
  uploadOmrFileHandlers: uploadOmrFileHandlers,
  loaduniversities: loaduniversities,
  loadtickets: loadtickets,
  loademploye: loademploye,
  getCollegeOnDeggrp: getCollegeOnDeggrp,
  getDegreeOnDeggrpCollege: getDegreeOnDeggrpCollege,
  loadempl: loadempl,
  getdeggrpresult: getdeggrpresult,
  getExamdateResult: getExamdateResult,
  getDegreeResult: getDegreeResult,
  getSemResult: getSemResult,
  viewResultDate: viewResultDate,
  mpcDeggrp: getMpcDeggrp,
  mpcExamdate: getMpcExamDate,
  mpcData: getMpcManageData,
  setExamDate: getExamDateval,
  getDegreeGroupDegReducer: getDegreeGroupDegReducer,
  getRvDatesReducer: getRvDatesReducer,
  getRVStatsReducer: getRVStatsReducer,
  getRVQPDeatilsReducer: getRVQPDeatilsReducer,
  getUUCMSExmDate: getUUCMSExmDate,
  getUUCMSQPC: getUUCMSQPC,
  getUUCMSData: getUUCMSData,
  getDataQP: getDataQP,
  uucmsPacketNO: uucmsPacketNO,
  upldUUCMS: upldUUCMS,
  omrQp: getOmrQuestionPapers,
  finalOmrQp: getSelectedMCQFinalPapers,
  getThQpDet: getPrepareThQpDetails,
  getSummaryQpDet: getPrepareQpSummaryDet,
  partDesc: getTempHeadSection,
  getQuestions: getQuestions,
  getMessage: saveQuestions,
  error_code: getErrorCode,
  templateCode: checkTemplateCode,
  availableQp: getAvailableQpDetails,
  getModQPOnline: getModQPOnline,
  aviQPSubj: getQPBoardSubj,
  qpDeggrp: getQpDeggrp,
  qpExam: getExamDetails,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
