export const getexamappcollege = (state = [], action) => {
  switch (action.type) {
    case "EXAM_APP_COLLEGE":
      return action.payload.data;
    case "CLEAR_EXAM_APP_COLLEGE":
      return [];
    default:
      return state;
  }
};

export const getexamappdegree = (state = [], action) => {
  switch (action.type) {
    case "EXAM_APP_DEGREE":
      return action.payload.data;
    case "CLEAREXAM_APP_DEGREE":
      return [];
    default:
      return state;
  }
};

export const getexamappexamno = (state = [], action) => {
  switch (action.type) {
    case "EXAM_APP_EXAMNO":
      return action.payload.data;
    case "CLEAREXAM_EXAM_APP_EXAMNO":
      return [];
    default:
      return state;
  }
};
