export const getprogramedet = (state = [], action) => {
  switch (action.type) {
    case "GET_PROGRAMEE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const loaddegreeheads = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_HEADS_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const loadeditingDet = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFSUM_DET":
      return action.payload.data;
    default:
      return state;
  }
};
export const loadeditdet = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFSUM_DETIALS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getDatesEntryForm = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_NOTIFICTN_DATES":
      return action.payload.data;
    case "CLEAR_NOTIFCTN_DATES_DATA":
      return [];
    default:
      return state;
  }
};

export const getVerfyData = (state = [], action) => {
  switch (action.type) {
    case "GET_VERF_NOTI":
      return action.payload.data;
    case "CLEAR_GET_VERF_NOTI":
      return [];
    default:
      return state;
  }
};

export const viewNotifdet = (state = [], action) => {
  switch (action.type) {
    case "VIEW_NOTIF_DET":
      return action.payload.data;
    case "CLEAR_VIEW_NOTIF_DET":
      return [];
    default:
      return state;
  }
};
