export const getdatedeg = (state = [], action) => {
  switch (action.type) {
    case "GET_DATE_DEGREE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getdatesem = (state = [], action) => {
  switch (action.type) {
    case "GET_DATE_SEM_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getdatesub = (state = [], action) => {
  switch (action.type) {
    case "GET_DATE_SUB_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getdatelement = (state = [], action) => {
  switch (action.type) {
    case "GET_DATE_ELEMENT_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const viewdatesentry = (state = [], action) => {
  switch (action.type) {
    case "VIEW_DATE_TABLE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getMaxTest = (state = [], action) => {
  switch (action.type) {
    case "GET_NO_TEST":
      return action.payload.data;
    default:
      return state;
  }
};
