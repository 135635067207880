export const getBundledeggrp = (state = [], action) => {
  switch (action.type) {
    case "BUNDLE_DEGGRP":
      return action.payload.data;
    case "CLEAR_BUNDLE_DEGGRP":
      return [];
    default:
      return state;
  }
};

export const getExamDate = (state = [], action) => {
  switch (action.type) {
    case "EXAM_DATE":
      return action.payload.data;
    case "CLEAR_EXAM_DATE":
      return [];
    default:
      return state;
  }
};

export const getbundledispatch = (state = [], action) => {
  switch (action.type) {
    case "LOAD_BUNDLE_DISPATCH":
      return action.payload.data;
    case "CLEAR_LOAD_BUNDLE_DISPATCH":
      return [];
    default:
      return state;
  }
};
