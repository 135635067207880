const initstate = [];

export const dispmarkscard = (state = initstate, action) => {
  switch (action.type) {
    case "DISPATCH_MARKSCARD_CHANGE":
      const olddisp = state.map((el, i) => {
        if (action.payload.i == i) {
          return action.payload.el;
        } else {
          return el;
        }
      });
      return [...olddisp];

    case "DISPATCH_MARKSCARD":
      return action.payload.data;
    case "CLEAR_DISPATCH_MARKSCARD":
      state = undefined;
      return initstate;
    case "DISP_EXAMSECTION_SELECT_ALL_CHECKBOX":
      const ele = state.map((el, j) => {
        if (action.payload == true) {
          el.fexamsent = "T";
        } else {
          el.fexamsent = "F";
        }
        return el;
      });
      return [...ele];
    default:
      return state;
  }
};

export const dispcollegelist = (state = [], action) => {
  switch (action.type) {
    case "DISP_COLLEGE_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

export const dispmarkscardclg = (state = initstate, action) => {
  switch (action.type) {
    case "DISPATCH_MARKSCARD_CLG_CHANGE":
      const olddisp = state.map((el, i) => {
        if (action.payload.i == i) {
          return action.payload.el;
        } else {
          return el;
        }
      });
      return [...olddisp];

    case "DISPATCH_MARKSCARD_CLG":
      return action.payload.data;
    case "CLEAR_DISPATCH_MARKSCARD_CLG":
      state = undefined;
      return initstate;
    case "DISP_COLL_SELECT_ALL_CHECKBOX":
      const ele = state.map((el, j) => {
        if (action.payload == true) {
          el.fcollsent = "T";
        } else {
          el.fcollsent = "F";
        }
        return el;
      });
      return [...ele];
    default:
      return state;
  }
};

export const dispdetails = (state = [], action) => {
  switch (action.type) {
    case "DISPATCH_DETAILS":
      return action.payload.data;
    default:
      return state;
  }
};
export const dispdetailsclg = (state = [], action) => {
  switch (action.type) {
    case "DISPATCH_DETAILS_CLG":
      return action.payload.data;
    default:
      return state;
  }
};
