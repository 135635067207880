const initstate = { fboardcode: "", fboardname: "", fdeleted: "F", fedit: "T" };
export const boardList = (state = [initstate], action) => {
  switch (action.type) {
    case "GET_BOARD_LIST":
      return action.payload.data;

    case "CHANGE_BOARDDET":
      let { el, i } = action.payload;
      return state.map((item, j) => {
        let data = item;
        if (j === i) data = { ...el };
        return data;
      });

    case "ADD_BOARDS":
      // let { newRow } = action.payload;
      return [...state, initstate];

    default:
      return state;
  }
};

export const getTeachLists = (state = [], action) => {
  switch (action.type) {
    case "GET_TEACH_LIST_MASS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getBoardEditLists = (state = [], action) => {
  switch (action.type) {
    case "GET_EDIT_BOARD_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

export const getChairmanCode = (state = [], action) => {
  switch (action.type) {
    case "GET_CHAIRMAN_CODE":
      return action.payload.data;
    default:
      return state;
  }
};

export const getChairmanDetails = (state = [], action) => {
  switch (action.type) {
    case "GET_CHAIRMAN_DETAILS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getChairmanCodeDetailsMass = (state = [], action) => {
  switch (action.type) {
    case "GET_CHAIRMAN_CODE_DETAILS_MASS":
      return action.payload.data;
    default:
      return state;
  }
};
