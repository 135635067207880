export const getTeacherDesignation = (state = [], action) => {
  switch (action.type) {
    case "TEACHER_DESIGNATION":
      return action.payload.data;
    default:
      return state;
  }
};

export const getTeachBoardDetails = (state = [], action) => {
  switch (action.type) {
    case "TEACHER_BOARD":
      return action.payload.data;
    case "CLEAR_TEACHER_BOARD":
      return state;
    default:
      return state;
  }
};

export const getQPSubjects = (state = [], action) => {
  switch (action.type) {
    case "TEACHER_BOARD_DETAILS":
      return action.payload.data;
    case "CLEAR_TEACHER_BOARD_DETAILS":
      return state;
    default:
      return state;
  }
};

export const viewTeacherEntry = (state = [], action) => {
  switch (action.type) {
    case "VIEW_TEACHER_ENTRY_DATA":
      return action.payload.data;
    case "CLEAR_TEACHER_ENTRY_DATA":
      return state;
    default:
      return state;
  }
};

export const getTeacherData = (state = [], action) => {
  switch (action.type) {
    case "GET_TEACHER_ENTRY_DATA":
      return action.payload.data;
    case "CLEAR_GET_TEACHER_ENTRY_DATA":
      return state;
    default:
      return state;
  }
};

export const loadYearAndMode = (state = [], action) => {
  switch (action.type) {
    case "LOAD_YEAR_AND_MODE":
      return action.payload.data;
    case "CLEAR_YEAR_AND_MODE":
      return state;
    default:
      return state;
  }
};

export const assignModeratorDet = (state = [], action) => {
  switch (action.type) {
    case "ASSIGN_MODERATOR":
      return action.payload.data;
    case "CLEAR_ASSIGN_MODERATOR":
      return [];
    default:
      return state;
  }
};

export const qpModeratorSummary = (state = [], action) => {
  switch (action.type) {
    case "QP_ASSIGN_MODERATOR_SUMMARY":
      return action.payload.data;
    case "CLEAR_QP_ASSIGN_MODERATOR_SUMMARY":
      return [];
    default:
      return state;
  }
};

export const viewQuestionPaper = (state = [], action) => {
  switch (action.type) {
    case "VIEW_QUESTION_PAPER":
      return action.payload.data;
    case "CLEAR_QUESTION_PAPER":
      return [];
    default:
      return state;
  }
};

export const viewQPPatternHandler = (state = [], action) => {
  switch (action.type) {
    case "QP_PATTERN":
      return action.payload.data;
    case "CLEAR_QP_PATTERN":
      return [];
    default:
      return state;
  }
};

export const viewFinalQp = (state = [], action) => {
  switch (action.type) {
    case "QP_FINAL_PAPER":
      return action.payload.data;
    case "CLEAR_QP_FINAL_PAPER":
      return [];
    default:
      return state;
  }
};

export const getModeratorData = (state = [], action) => {
  switch (action.type) {
    case "QP_MOD_DET":
      return action.payload.data;
    case "CLEAR_QP_MOD_DET":
      return [];
    default:
      return state;
  }
};

export const getModUpdData = (state = [], action) => {
  switch (action.type) {
    case "MOD_UPD":
      return action.payload.data;
    case "CLEAR_MOD_UPD":
      return [];
    default:
      return state;
  }
};

export const selectFinalQp = (state = [], action) => {
  switch (action.type) {
    case "GET_FINAL_QP_SELECT":
      return action.payload.data;
    case "CLEAR_FINAL_QP_SELECT":
      return [];
    default:
      return state;
  }
};

export const getModeratorDet = (state = [], action) => {
  switch (action.type) {
    case "GET_MODE_DETI":
      return action.payload.data;
    case "CLEAR_MODE_DETI":
      return [];
    default:
      return state;
  }
};

export const getModQPData = (state = [], action) => {
  switch (action.type) {
    case "UPLD_DATA":
      return action.payload.data;
    case "CLEAR_UPLD_DATA":
      return [];
    default:
      return state;
  }
};
