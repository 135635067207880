const GetNotVal = (state = { valuatordet: [] }, action) => {
  switch (action.type) {
    case "GET_NOT_VAL":
      return action.payload;
    default:
      return state;
  }
};
export { GetNotVal };

export const fetchDropNotValued = (state = { board: [] }, action) => {
  switch (action.type) {
    case "GET_DROP_DOWN_VALUE":
      return { board: action.payload.data };

    default:
      return state;
  }
};

export const getCollegenotVal = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGE_LIST_VALUE":
      return action.payload.data;
    default:
      return state;
  }
};
