export const getvalheads = (state = [], action) => {
  switch (action.type) {
    case "GET_VAL_HEADS":
      return action.payload.data;
    case "CLEAR_VALCNTR":
      return [];

    default:
      return state;
  }
};
