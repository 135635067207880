const initistate = {
  fid: "",
  fltfrom: "",
  fcollcode: "",
  college: [],
  to: [],
  fto: "",
  fname: "",
  fsubject: "",
  fregno: "",
  fothers: "",
  fconfi: "F",
};

export const inwardCreation = (state = initistate, action) => {
  switch (action.type) {
    case "INWARD_LOAD":
      return {
        ...state,
        college: action.payload.data.college,
        to: action.payload.data.masto,
      };
    case "CHANGE_INWARD":
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case "INWARD_GETNAME":
      return {
        ...state,
        fname: action.payload.data.fname,
      };
    case "INWARD_FORMDATA":
      return action.payload.data;
    case "CANCEL_INWARD":
      return initistate;
    default:
      return state;
  }
};

export const viewinword = (state = [], action) => {
  switch (action.type) {
    case "VIEW_INWARD":
      return action.payload.data;
    default:
      return state;
  }
};
