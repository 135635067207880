export const FETCH_DEGREES = "FETCH_DEGREES";
export const FETCH_DEGGRP = "FETCH_DEGGRP";

export const FETCH_COMBS = "FETCH_COMBS";
export const FETCH_COMBSUBS = "FETCH_COMBSUBS";
export const CHANGE_COMB = "CHANGE_COMB";
export const ADD_COMB_SUB = "ADD_COMB_SUB";
export const CANCEL_COMB = "CANCEL_COMB";
export const GET_REFUND_LIST = "GET_REFUND_LIST";
export const SAVE_INVITE_DATA = "SAVE_INVITE_DATA";
export const SAVE_INVITE_LIST = "SAVE_INVITE_LIST";
export const GET_DAILY_VALUATION = "GET_DAILY_VALUATION";
