export const getDegrGrp = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_GROUP":
      return action.payload.data;
    case "CLEAR_DEGREE_GROUP":
      return [];
    default:
      return state;
  }
};

export const scriptBoard = (state = [], action) => {
  switch (action.type) {
    case "GET_SCRIPT_BOARD":
      return action.payload.data;
    case "CLEAR_SCRIPT_BOARD":
      return [];
    default:
      return state;
  }
};

export const qpRecjDet = (state = [], action) => {
  switch (action.type) {
    case "GET_QP_RECJ_DET":
      return action.payload.data;
    case "CLEAR_QP_RECJ_DET":
      return [];
    default:
      return state;
  }
};

export const getRejScrCheck = (state = [], action) => {
  switch (action.type) {
    case "GET_RECJ_SCR_DET":
      return action.payload.data;
    case "CLEAR_RECJ_SCR_DET":
      return [];
    default:
      return state;
  }
};
