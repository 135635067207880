export const getTdvsBoardDetails = (state = [], action) => {
  switch (action.type) {
    case "TDVS_BOARD_DETAILS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getTdvsQPDetails = (state = [], action) => {
  switch (action.type) {
    case "TDVS_QP_DETAILS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getTDVSQpPdfDet = (state = [], action) => {
  switch (action.type) {
    case "TDVS_PDF_DETAILS":
      return action.payload.data;
    case "CLEAR_TDVS_PDF_DETAILS":
      return [];
    default:
      return state;
  }
};

export const getTDVSStudentDeatils = (state = [], action) => {
  switch (action.type) {
    case "TDVS_STUDENT_DETAILS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getTdvsReport = (state = [], action) => {
  switch (action.type) {
    case "VIEW_TDVS_REPORT_DETAILS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getDecodingData = (state = [], action) => {
  switch (action.type) {
    case "GET_DECODE_DATA":
      return action.payload.data;
    case "CLEAR_DECODE_DATA":
      return [];
    default:
      return state;
  }
};

export const getDecodeDiff = (state = [], action) => {
  switch (action.type) {
    case "GET_DECODE_DIFF":
      return action.payload.data;
    case "CLEAR_DECODE_DIFF":
      return [];
    default:
      return state;
  }
};

export const saveUpdatedRegAnsNo = (state = [], action) => {
  switch (action.type) {
    case "SAVE_DECODE_DIFF":
      return action.payload.data;
    case "CLEAR_SAVE_DECODE_DIFF":
      return [];
    default:
      return state;
  }
};

export const getTdvsCountData = (state = [], action) => {
  switch (action.type) {
    case "TDVS_DECODING_COUNT":
      return action.payload.data;
    case "CLEAR_TDVS_DECODING_COUNT":
      return [];
    default:
      return state;
  }
};

export const getUUCMSExmDate = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_DEGG":
      return action.payload.data;
    case "CLEAR_UUCMS_DEGG":
      return [];
    default:
      return state;
  }
};

export const upldUUCMS = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_UPD_DET":
      return action.payload.data;
    case "CLEAR_UUCMS_UPD_DET":
      return [];
    default:
      return state;
  }
};

export const getUUCMSQPC = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_QPC":
      return action.payload.data;
    case "CLEAR_UUCMS_QPC":
      return [];
    default:
      return state;
  }
};

export const uucmsPacketNO = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_PACKET":
      return action.payload.data;
    case "CLEAR_UUCMS_PACKET":
      return [];
    default:
      return state;
  }
};

export const getUUCMSData = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_QP_DATA":
      return action.payload.data;
    case "CLEAR_UUCMS_QP_DATA":
      return [];
    default:
      return state;
  }
};

export const getDataQP = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_QP_VERF":
      return action.payload.data;
    case "CLEAR_UUCMS_QP_VERF":
      return [];
    default:
      return state;
  }
};

export const getErrorCode = (state = [], action) => {
  switch (action.type) {
    case "UUCMS_ERROR_CODE":
      return action.payload;
    default:
      return state;
  }
};
