export const loadunivall = (state = [], action) => {
    switch (action.type) {
      case "LOAD_UNIV_ALL":     
        return action.payload;
      default:
        return state;
    }
  };

  export const loadmodcorr = (state = [], action) => {
    switch (action.type) {
      case "LOAD_MOD_CORR":   
        if(action.payload.error_code == '0')  
          return action.payload.data.data;
        else 
          return [];
      default:
        return state;
    }
  };
  

  