export const lstDateUpdateRedu = (state = [], action) => {
  switch (action.type) {
    case "LASTDATE_UPDATE": {
      return action.payload;
    }

    default:
      return state;
  }
};

export const getCollegesAsperDeggrp = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGE_ASPER_DEGGRP": {
      return action.payload;
    }
    default:
      return state;
  }
};
export const getDegreesAsperDeggrp = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_ASPER_DEGGRP": {
      return action.payload;
    }
    default:
      return state;
  }
};
