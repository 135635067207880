export const updatevalcenter = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_VAL_CENTER":
      return action.payload.data;
    case "CLEAR_UPDATE_VAL_CENTER":
      return [];
    default:
      return state;
  }
};
const initstate = { fdeggrp: [], fvalcntr: [] };
export const getdegreevalcenter = (state = initstate, action) => {
  switch (action.type) {
    case "GET_DEGREE_CENTER":
      return action.payload.data;
    case "CLEAR_DEGREE_CENTER":
      return state;

    default:
      return state;
  }
};
