export const getMpcDeggrp = (state = [], action) => {
  switch (action.type) {
    case "MPC_DEGGRP":
      return action.payload;
    case "CLEAR_MPC_DEGGRP":
      return [];
    default:
      return state;
  }
};

export const getMpcExamDate = (state = [], action) => {
  switch (action.type) {
    case "MPC_EXAMDATE":
      return action.payload;
    case "CLEAR_MPC_EXAMDATE":
      return [];
    default:
      return state;
  }
};

export const getMpcManageData = (state = [], action) => {
  switch (action.type) {
    case "MPC_DATA":
      return action.payload;
    case "CLEAR_MPC_DATA":
      return [];
    default:
      return state;
  }
};
