export const loadtdvssubject = (state = [], action) => {
  switch (action.type) {
    case "GET_TDVS_SUBJECT_DATA":
      return action.payload.data;
    case "CLEAR_TDVS_SUBJECT_DATA":
      return [];
    default:
      return state;
  }
};

export const loadtdvssubj = (state = [], action) => {
  switch (action.type) {
    case "GET_TDVS_SUBJ_DATA":
      return action.payload.data;
    case "CLEAR_TDVS_SUBJ_DATA":
      return [];
    default:
      return state;
  }
};

export const loadalldata = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return action.payload.data;
    case "CLEAR_ALL_DATA":
      return [];
    default:
      return state;
  }
};

export const loadtdvsdegree = (state = [], action) => {
  switch (action.type) {
    case "GET_TDVS_DEG_DATA":
      return action.payload.data;
    case "CLEAR_TDVS_DEG_DATA":
      return [];
    default:
      return state;
  }
};

export const loadtdvsdate = (state = [], action) => {
  switch (action.type) {
    case "GET_DATES_DATA":
      return action.payload.data;
    case "CLEAR_DATES_DATA":
      return [];
    default:
      return state;
  }
};

export const loadtdvsstats = (state = [], action) => {
  switch (action.type) {
    case "GET_TDVS_STATS_DATA":
      return action.payload.data;
    case "CLEAR_TDVS_STATS_DATA":
      return [];
    default:
      return state;
  }
};

export const loadqpdetails = (state = [], action) => {
  switch (action.type) {
    case "GET_QPCODE_DATA":
      return action.payload.data;
    case "CLEAR_QPCODE_DATA":
      return [];
    default:
      return state;
  }
};
