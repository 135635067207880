export const loaddegreegrouptm = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGGRPTM_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getdegreetmdet = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREETM_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getsemtmdet = (state = [], action) => {
  switch (action.type) {
    case "GET_SEMTM_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getsubjtmdet = (state = [], action) => {
  switch (action.type) {
    case "GET_SUBJTM_DATA":
      return action.payload.data;
    case "CLEAR_SUBJTM_INFO_DATA":
      return [];
    default:
      return state;
  }
};

export const loadteacherinfotm = (state = [], action) => {
  switch (action.type) {
    case "GET_TEACH_INFO_DATA":
      return action.payload.data;

    default:
      return state;
  }
};

export const loadtableinfotm = (state = [], action) => {
  switch (action.type) {
    case "GET_TABLE_INFO_DATA":
      return action.payload.data;
    case "CLEAR_GET_TEACH_INFO_DATA":
      return [];
    default:
      return state;
  }
};

export const loadergrpview = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGGRPVIEW_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getdegreeviewdet = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREEVIEW_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getsemviewdet = (state = [], action) => {
  switch (action.type) {
    case "GET_SEMVIEW_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const loadtableinfoview = (state = [], action) => {
  switch (action.type) {
    case "GET_VIEW_TABLE_INFO_DATA":
      return action.payload.data;
    case "CLEAR_VIEW_TABLE_INFO_DATA":
      return [];
    default:
      return state;
  }
};
