import React, { useState, useReducer } from "react";

export const AppContext = React.createContext(null);

function messgeBoxReducer(state, action) {
  switch (action.type) {
    case "CLOSE_MSG_BOX":
      return { open: false, header: "", message: "", color: "" };
    case "OPEN_MSG_BOX":
      let color =
        action.payload.header.toLowerCase() == "error" ? "#e53935" : "#388e3c";
      return {
        open: true,
        header: action.payload.header,
        message: action.payload.message,
        color: color,
      };
    default:
      return state;
  }
}

export default ({ children }) => {
  const initMgsState = { open: false, header: "", message: "", color: "" };

  const [error, dispatch_err] = useReducer(messgeBoxReducer, initMgsState);
  const [loader, setLoader] = useState(false);

  const appStore = {
    err_msg: [error, dispatch_err],
    load: [loader, setLoader],
  };
  return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>;
};
