export const deggrpexamcentre = (state = [], action) => {
  switch (action.type) {
    case "LOAD_DEGGRP_EXAMCENTRE":
      return action.payload.data;
    case "CLEAR_LOAD_DEGGRP_EXAMCENTRE":
      return [];
    default:
      return state;
  }
};

const initstate = {
  date: [],
  sub: [],
  degree: [],
  centre: [],
  session: [],
};
export const allexamcentre = (state = initstate, action) => {
  switch (action.type) {
    case "LOAD_ALL_EXAMCENTRE":
      return action.payload.data;
    case "CLEAR_LOAD_ALL_EXAMCENTRE":
      return [];
    default:
      return state;
  }
};

const initstate1 = {
  sub: [],
  degree: [],
  centre: [],
  session: [],
};
export const dateall = (state = initstate1, action) => {
  switch (action.type) {
    case "LOAD_ALL_DATE":
      return action.payload.data;
    case "CLEAR_LOAD_ALL_DATE":
      return [];
    default:
      return state;
  }
};

export const loadexamcentretable = (state = [], action) => {
  switch (action.type) {
    case "LOAD_TABLE_EXAMCENTRE":
      return action.payload.data;
    case "CLEAR_LOAD_TABLE_EXAMCENTRE":
      return [];
    default:
      return state;
  }
};

export const loadmastersdeg = (state = [], action) => {
  switch (action.type) {
    case "LOAD_MASTERS_DEGREE":
      return action.payload.data;
    case "CLEAR_LOAD_MASTERS_DEGREE":
      return [];
    default:
      return state;
  }
};

export const loadsubrptsem = (state = [], action) => {
  switch (action.type) {
    case "LOAD_SUB_SEMESTER":
      return action.payload.data;
    case "CLEAR_LOAD_SUB_SEMESTER":
      return [];
    default:
      return state;
  }
};
