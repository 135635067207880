const getResStudDegree = (state = [], action) => {
  switch (action.type) {
    case "RES_STUD_DEGREE":
      return action.payload.data;
    default:
      return state;
  }
};
const getResStudSem = (state = [], action) => {
  switch (action.type) {
    case "RES_STUD_SEM":
      return action.payload.data;
    default:
      return state;
  }
};
const getResStudSubject = (state = [], action) => {
  switch (action.type) {
    case "RES_STUD_SUBJECT":
      return action.payload.data;
    default:
      return state;
  }
};
const getResStudData = (state = [], action) => {
  switch (action.type) {
    case "RES_STUD_DATA":
      return action.payload.data;
    case "CLEAR_RES_STUD_DATA":
      return [];
    default:
      return state;
  }
};

export { getResStudDegree, getResStudSem, getResStudSubject, getResStudData };
