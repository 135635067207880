export const getQpSetterDetData = (state = [], action) => {
  switch (action.type) {
    case "QP_SETTER_ACCEPT":
      return action.payload.data;
    case "CLEAR_QP_SETTER_ACCEPT":
      return [];
    default:
      return state;
  }
};

export const getUploadQPseterDetData = (state = [], action) => {
  switch (action.type) {
    case "UPLOAD_PREPARE_QP":
      return action.payload.data;
    case "CLEAR_UPLOAD_PREPARE_QP":
      return [];
    default:
      return state;
  }
};

export const selectModerator = (state = [], action) => {
  switch (action.type) {
    case "SELECT_ASSIGN_MOD":
      return action.payload.data;
    case "CLEAR_SELECT_ASSIGN_MOD":
      return [];
    default:
      return state;
  }
};

export const getQpSummaryNew = (state = [], action) => {
  switch (action.type) {
    case "GET_QP_SUMMARY":
      return action.payload.data;
    case "CLEAR_GET_QP_SUMMARY":
      return [];
    default:
      return state;
  }
};

export const getDetaileQpSummary = (state = [], action) => {
  switch (action.type) {
    case "GET_DETAILED_QP":
      return action.payload.data;
    case "CLEAR_GET_DETAILED_QP":
      return [];
    default:
      return state;
  }
};

export const getFinalQuestionPaperSumData = (state = [], action) => {
  switch (action.type) {
    case "GET_FINAL_SUM_QP":
      return action.payload.data;
    case "CLEAR_GET_FINAL_SUM_QP":
      return [];
    default:
      return state;
  }
};

export const showFinalQPFinalDetdata = (state = [], action) => {
  switch (action.type) {
    case "GET_FINAL_DET_QP":
      return action.payload.data;
    case "CLEAR_GET_FINAL_DET_QP":
      return [];
    default:
      return state;
  }
};
