export const headsdetails = (state = [], action) => {
  switch (action.type) {
    case "GET_HEADS_DATA_DATA":
      return action.payload.data;
    case "CLEAR_HEADS_DATA":
      return [];
    default:
      return state;
  }
};
