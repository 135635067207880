export const oldquestionpaperdegree = (state = [], action) => {
  switch (action.type) {
    case "GET_OLD_QP_DEGREE":
      return action.payload.data;
    default:
      return state;
  }
};

export const oldquestionpaperexamno = (state = [], action) => {
  switch (action.type) {
    case "GET_OLD_QP_EXAMNO":
      return action.payload.data;
    default:
      return state;
  }
};

export const oldquestionpaperexamdate = (state = [], action) => {
  switch (action.type) {
    case "GET_OLD_QP_EXAMDATE":
      return action.payload.data;
    default:
      return state;
  }
};

export const getoldqpdet = (state = [], action) => {
  switch (action.type) {
    case "GET_OLD_QP_DET":
      return action.payload.data;
    default:
      return state;
  }
};
