export const allClgList = (state = [], action) => {
  switch (action.type) {
    case "ALL_COLLEGE_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

export const GetSpecificCollege = (state = [], action) => {
  switch (action.type) {
    case "GET_SPECIFIC_COLLEGE":
      return action.payload.data;
    default:
      return state;
  }
};

export const GetDeggrpOnCollege = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGE_DEGGRP":
      return action.payload.data;
    case "CLEAR_GET_COLLEGE_DEGGRP":
      return [];
    default:
      return state;
  }
};

export const getDegreeOnCol = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGE_DEGREE":
      return action.payload.data;
    case "CLEAR_GET_COLLEGE_DEGREE":
      return [];
    default:
      return state;
  }
};

export const getIaShortUpdData = (state = [], action) => {
  switch (action.type) {
    case "GET_ATTENDANCE_IA_SHORTAGE":
      return action.payload.data;
    case "CLEAR_GET_ATTENDANCE_IA_SHORTAGE":
      return [];
    default:
      return state;
  }
};
