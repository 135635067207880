export const uploaddoccorr = (state = [], action) => {
  switch (action.type) {
    case "UPLOAD_CORR_DOC":
      return action.payload.data;
    case "CLEAR_UPLOAD_CORR_DOC":
      return [];
    default:
      return state;
  }
};

export const viewuploaddoccorr = (state = [], action) => {
  switch (action.type) {
    case "VIEW_CORRECTION_DOC":
      return action.payload.data;
    case "CLEAR_VIEW_CORRECTION_DOC":
      return [];
    default:
      return state;
  }
};

export const regcodeverification = (state = [], action) => {
  switch (action.type) {
    case "REGCODE_VERIFICATION":
      return action.payload.data;
    case "CLEAR_REGCODE_VERIFICATION":
      return [];
    default:
      return state;
  }
};
