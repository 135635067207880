export const fetchBoardDet = (state = [], action) => {
  switch (action.type) {
    case "FETCH_BOARDS_DETAILS":
      return action.payload;
    default:
      return state;
  }
};

export const getTCode = (state = [], action) => {
  switch (action.type) {
    case "GET_TEACH_CODE_DET":
      return action.payload;
    default:
      return state;
  }
};

export const getCollCode = (state = [], action) => {
  switch (action.type) {
    case "GET_COLL_CODE_DET":
      return action.payload;
    default:
      return state;
  }
};

export const getCollCodefrmdeggrp = (state = [], action) => {
  switch (action.type) {
    case "GET_COLL_CODE_DET_DATA":
      return action.payload;
    default:
      return state;
  }
};
