const init = [];

export const viewbackup = (state = [], action) => {
  switch (action.type) {
    case "VIEW_BACKUP":
      return action.payload.data;
    case "RESET_BKP":
      return init;
    default:
      return state;
  }
};

export const getUplaodBackUpUnivs = (state = [], action) => {
  switch (action.type) {
    case "GET_UPLOAD_UNIVS":
      return action.payload.data;
    default:
      return state;
  }
};
