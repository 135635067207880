export const getTimeTablesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_VIEW_TIME_TABLE":
      return action.payload.data;
    case "DEL_VIEW_TIME_TABLE":
      return [];
    default:
      return state;
  }
};

export const getdegreesttm = (state = [], action) => {
  switch (action.type) {
    case "FETCH_DEGREES_TTM":
      return action.payload.data;
    case "DEL_FETCH_DEGREES_TTM":
      return [];
    default:
      return state;
  }
};
