export const getTeachData = (state = [], action) => {
  switch (action.type) {
    case "GET_TEACH_LIST":
      return action.payload.data;
    case "CLEAR_TEACH_LIST":
      return [];
    default:
      return state;
  }
};

export const getExamOrderDet = (state = [], action) => {
  switch (action.type) {
    case "GET_ORDER_DET":
      return action.payload.data;
    case "CLEAR_ORDER_DET":
      return [];
    default:
      return state;
  }
};

export const getOrderData = (state = [], action) => {
  switch (action.type) {
    case "GET_ORDERS_DAT":
      return action.payload.data;
    case "CLEAR_ORDERS_DAT":
      return [];
    default:
      return state;
  }
};
