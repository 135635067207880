export const getStudNameChange = (state = [], action) => {
  switch (action.type) {
    case "APPROVE_STUD_NAME":
      return action.payload.data;
    case "CLEAR_APPROVE_STUD_NAME":
      return [];
    default:
      return state;
  }
};

export const getTeachIdData = (state = [], action) => {
  switch (action.type) {
    case "TEACH_DATA":
      return action.payload.data;
    case "CLEAR_TEACH_DATA":
      return [];
    default:
      return state;
  }
};

export const getExamDateval = (state = [], action) => {
  switch (action.type) {
    case "QP_EXAM_DATE":
      return action.payload.data;
    case "CLEAR_QP_EXAM_DATE":
      return [];
    default:
      return state;
  }
};
