const initstate = { relarr: [], rldisable: false };
export const getreleasetimetablereducer = (state = initstate, action) => {
  switch (action.type) {
    case "GET_RELEASE_TIME_TABLE_CHANGE":
      const oldrelease = state.relarr.map((el, i) => {
        if (action.payload.i == i) {
          return action.payload.el;
        } else {
          return el;
        }
      });
      return { relarr: oldrelease, rldisable: true };
    case "GET_RELEASE_TIME_TABLE":
      let dismap = false;
      action.payload.data.map((el, i) => {
        if (el.frelease == "T") {
          dismap = true;
        } else {
          dismap = false;
        }
      });

      return { relarr: action.payload.data, rldisable: dismap };
    case "CLEAR_RELEASE_TIME_TABLE":
      state = undefined;
      return initstate;
    case "RELEASE_SELECT_ALL_CHECKBOX":
      const ele = state.relarr.map((el, j) => {
        if (action.payload == true) {
          el.frelease = "T";
        } else {
          el.frelease = "F";
        }
        return el;
      });
      return { ...state, relarr: ele, rldisable: true };

    default:
      return state;
  }
};
