export const getPrepareThQpDetails = (state = [], action) => {
  switch (action.type) {
    case "THEORY_QP_DET":
      return action.payload;
    case "CLEAR_THEORY_QP_DET":
      return action.payload;
    default:
      return state;
  }
};

export const getPrepareQpSummaryDet = (state = [], action) => {
  switch (action.type) {
    case "THEORY_QP_DET_SUMMARY":
      return action.payload;
    case "CLEAR_THEORY_QP_DET_SUMMARY":
      return action.payload;
    default:
      return state;
  }
};

export const getTempHeadSection = (state = [], action) => {
  switch (action.type) {
    case "GET_PART_CODE":
      return action.payload;
    case "CLEAR_GET_PART_CODE":
      return action.payload;
    default:
      return state;
  }
};

export const getQuestions = (state = [], action) => {
  switch (action.type) {
    case "GET_QUESTIONS":
      return action.payload;
    case "CLEAR_QUESTIONS":
      return action.payload;
    default:
      return state;
  }
};

export const saveQuestions = (state = [], action) => {
  switch (action.type) {
    case "GET_QP_MESSAGE":
      return action.payload;
    case "CLEAR_GET_QP_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

export const getModQPOnline = (state = [], action) => {
  switch (action.type) {
    case "MOD_QP_ONLINE":
      return action.payload;
    case "CLEAR_MOD_QP_ONLINE":
      return action.payload;
    default:
      return state;
  }
};
