export const degDet = (state = [], action) => {
  switch (action.type) {
    case "GET_SEL_DEG_CH":
      return action.payload.data;
    default:
      return state;
  }
};

export const collDet = (state = [], action) => {
  switch (action.type) {
    case "COLLEGE_DET":
      return action.payload.data;
    default:
      return state;
  }
};

export const boardDet = (state = [], action) => {
  switch (action.type) {
    case "BOARD_DET":
      return action.payload.data;
    default:
      return state;
  }
};

export const teachDet = (state = [], action) => {
  switch (action.type) {
    case "TEACH_DET":
      return action.payload.data;
    default:
      return state;
  }
};

export const getTeach = (state = [], action) => {
  switch (action.type) {
    case "DISPLAY_TEACH_DET":
      return action.payload.data;
    default:
      return state;
  }
};
