export const getOmrQpCode = (state = [], action) => {
  switch (action.type) {
    case "GET_OMR_QP":
      return action.payload;
    case "CLEAR_OMR_QP":
      return state;
    default:
      return state;
  }
};

export const getOmrYearMode = (state = [], action) => {
  switch (action.type) {
    case "GET_OMR_YEAR_MODE":
      return action.payload;
    case "CLEAR_OMR_YEAR_MODE":
      return state;
    default:
      return state;
  }
};

export const viewOmrMarksEnrtyDet = (state = [], action) => {
  switch (action.type) {
    case "VIEW_OMR_DATA":
      return action.payload;
    case "CLEAR_OMR_DATA":
      return state;
    default:
      return state;
  }
};

export const getOmrMCQQP = (state = [], action) => {
  switch (action.type) {
    case "OMR_MCQ_DATA":
      return action.payload;
    case "CLEAR_OMR_MCQ_DATA":
      return state;
    default:
      return state;
  }
};

export const saveOmrQp = (state = [], action) => {
  switch (action.type) {
    case "OMR_SAVE_QP_DATA":
      return action.payload;
    case "LOADING":
      return action.payload;
    default:
      return state;
  }
};

export const uploadOmrFileHandlers = (state = [], action) => {
  switch (action.type) {
    case "OMR_FILE_HANDLERS":
      return action.payload;
    default:
      return state;
  }
};

export const getOmrQuestionPapers = (state = [], action) => {
  switch (action.type) {
    case "OMR_QUESTION_PAPERS":
      return action.payload;
    case "CLEAR_OMR_QUESTION_PAPERS":
      return action.payload;
    default:
      return state;
  }
};

export const getSelectedMCQFinalPapers = (state = [], action) => {
  switch (action.type) {
    case "FINAL_OMR_QUESTION_PAPERS":
      return action.payload;
    case "CLEAR_FINAL_OMR_QUESTION_PAPERS":
      return action.payload;
    default:
      return state;
  }
};

export const getQpDeggrp = (state = [], action) => {
  switch (action.type) {
    case "DEGGRP":
      return action.payload;
    case "CLEAR_DEGGRP":
      return action.payload;
    default:
      return state;
  }
};

export const getExamDetails = (state = [], action) => {
  switch (action.type) {
    case "QP_EXAM_DET":
      return action.payload;
    case "CLEAR_QP_EXAM_DET":
      return action.payload;
    default:
      return state;
  }
};
