export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_CREDIT":
      if (action.payload.data.length === 0) {
        const item = {
          FFROMMARK: "",
          FTOMARK: "",
          FCREDIT: "",
          FGRADE: "",
          fdeleted: false,
        };
        return [item];
      } else {
        return action.payload.data;
      }
    case "CHANGE_CREDIT":
      let { el, i } = action.payload;
      return state.map((item, j) => {
        let data = item;
        if (j === i) {
          data = { ...el };
        }
        return data;
      });
    case "ADD_CREDIT":
      return [...state, action.payload.el];
    case "DELETE_CREDIT":
      return [];
    default:
      return state;
  }
};
