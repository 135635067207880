export const getDeggrp = (state = [], action) => {
  switch (action.type) {
    case "DEGREE_DEGGP_DET":
      return action.payload.data;
    case "CLEAR_DEGREE_DEGGP_DET":
      return [];
    default:
      return state;
  }
};

export const getExamDateDet = (state = [], action) => {
  switch (action.type) {
    case "EXAM_DETAILS":
      return action.payload.data;
    case "CLEAR_EXAM_DETAILS":
      return [];
    default:
      return state;
  }
};

export const getDeggrpDet = (state = [], action) => {
  switch (action.type) {
    case "DEGGRP_DETAILS":
      return action.payload.data;
    case "CLEAR_DEGGRP_DETAILS":
      return [];
    default:
      return state;
  }
};

export const getMarksDet = (state = [], action) => {
  switch (action.type) {
    case "MARKS_PRINT_DETAILS":
      return action.payload.data;
    case "CLEAR_MARKS_PRINT_DETAILS":
      return [];
    default:
      return state;
  }
};
