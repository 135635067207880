export const getcollegereport = (state = [], action) => {
  switch (action.type) {
    case "COLLEGE_REPORT":
      return action.payload.data;
    case "CLEAR_COLLEGE_REPORT":
      return [];
    default:
      return state;
  }
};

export const getdegreereport = (state = [], action) => {
  switch (action.type) {
    case "DEGREE_REPORT":
      return action.payload.data;
    case "CLEAR_DEGREE_REPORT":
      return [];
    default:
      return state;
  }
};

export const submitcollegereport = (state = [], action) => {
  switch (action.type) {
    case "SUBMIT_COLLEGE_REPORT":
      return action.payload.data;
    case "CLEAR_SUBMIT_COLLEGE_REPORT":
      return [];
    default:
      return state;
  }
};

export const loaddegreegroupclgrpt = (state = [], action) => {
  switch (action.type) {
    case "DEGGRP_COLLEGE_REPORT":
      return action.payload.data;
    case "CLEAR_DEGGRP_COLLEGE_REPORT":
      return [];
    default:
      return state;
  }
};

export const getCollAbsentReport = (state = [], action) => {
  switch (action.type) {
    case "COLL_ABS_REPORT":
      return action.payload.data;
    case "CLEAR_COLL_ABS_REPORT":
      return [];
    default:
      return state;
  }
};
