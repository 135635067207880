const upload_initstate = {
  fqpfilenm: ""
};

const studyDegreeListReducer = (state = [], action) => {
  switch (action.type) {
    case "ALL_DEGREE_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

const studyExamnoReducer = (state = [], action) => {
  switch (action.type) {
    case "STUDY_EXAMNO":
      return action.payload.data;
    default:
      return state;
  }
};

const studySubjectReducer = (state = [], action) => {
  switch (action.type) {
    case "STUDY_SUBJECT":
      return action.payload.data;
    default:
      return state;
  }
};

const studyUploadDocReducer = (state = upload_initstate, action) => {
  switch (action.type) {
    case "SET_UPLOAD_DOC":
      return { ...state, fqpfilenm: action.payload };
    default:
      return state;
  }
};
const getstudyMaterialReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_STUDY_MATERIAL":
      return action.payload.data;
    default:
      return state;
  }
};



export { studyDegreeListReducer, studyExamnoReducer,studySubjectReducer,studyUploadDocReducer, getstudyMaterialReducer }