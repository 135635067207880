export const getTeacherId = (state = [], action) => {
  switch (action.type) {
    case "TEACHER_ID":
      return action.payload.data;
    case "CLEAR_TEACHER_ID":
      return [];
    default:
      return state;
  }
};
