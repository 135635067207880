const initstate = { frzarr: [], frzdisable: false, freezeallcheck: false };
export const getfreezetimetablereducer = (state = initstate, action) => {
  switch (action.type) {
    case "GET_FREEZE_TIME_TABLE_CHANGE":
      let changecurtype = "";
      const oldfreeze = state.frzarr.map((el, i) => {
        changecurtype = el.fusertype == "100" ? "T" : "F";
        if (action.payload.i == i) {
          return action.payload.el;
        } else {
          return el;
        }
      });
      if (changecurtype == "T") {
        return { frzarr: oldfreeze, frzdisable: false };
      } else {
        return { frzarr: oldfreeze, frzdisable: true };
      }

    case "GET_FREEZE_TIME_TABLE":
      let curtype = "";
      let dismap = false;
      action.payload.data.map((el, i) => {
        curtype = el.fusertype == "100" ? "T" : "F";
        if (el.ffreeze == "T") {
          dismap = true;
        } else {
          dismap = false;
        }
      });
      const topmang = action.payload.data.map((el, i) => {
        if (curtype == "T") {
          el.frelease = "F";
        }
        return el;
      });

      if (curtype == "T") {
        return { ...state, frzarr: topmang, frzdisable: false };
      } else {
        return { frzarr: action.payload.data, frzdisable: dismap };
      }
    case "CLEAR_FREEZE_TIME_TABLE":
      state = undefined;
      return initstate;
    case "FREEZE_SELECT_ALL_CHECKBOX":
      const ele = state.frzarr.map((el, j) => {
        if (action.payload == true) {
          el.ffreeze = "T";
        } else {
          el.ffreeze = "F";
        }
        return el;
      });
      if (action.payload == true) {
        return {
          ...state,
          frzarr: ele,
          frzdisable: false,
          freezeallcheck: true,
        };
      } else {
        return {
          ...state,
          frzarr: ele,
          frzdisable: false,
          freezeallcheck: false,
        };
      }

    default:
      return state;
  }
};
