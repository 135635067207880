export const getregcodeyear = (state = [], action) => {
  switch (action.type) {
    case "GET_MODE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};

export const getregmode = (state = [], action) => {
  switch (action.type) {
    case "GET_QP_CODE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
export const getregqpcode = (state = [], action) => {
  switch (action.type) {
    case "GET_QP_PACKET_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
export const getregpacket = (state = [], action) => {
  switch (action.type) {
    case "GET_TABLE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
export const viewsearchregcode = (state = [], action) => {
  switch (action.type) {
    case "VIEW_TABLE_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
