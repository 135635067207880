export const viewteachattendcollege = (state = [], action) => {
  switch (action.type) {
    case "TEACH_ATTEND_COLLEGE":
      return action.payload.data;
    case "CLEAR_TEACH_ATTEND_COLLEGE":
      return [];
    default:
      return state;
  }
};

export const viewteachattenddet = (state = [], action) => {
  switch (action.type) {
    case "VIEW_TEACH_ATTEND_COLLEGE":
      return action.payload.data;
    case "CLEAR_VIEW_TEACH_ATTEND_COLLEGE":
      return [];
    default:
      return state;
  }
};
