const initistate = {
  fltto: "",
  fcollcode: "",
  college: [],
  to: [],
  fto: "",
  fname: "",
  fsubject: "",
  fregno: "",
  fothers: "",
  fconfi: "F"
};

export const outwardCreation = (state = initistate, action) => {
  switch (action.type) {
    case "OUTWARD_LOAD":
      return {
        ...state,
        college: action.payload.data.college,
        to: action.payload.data.masto
      };
    case "CHANGE_OUTWARD":
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case "OUTWARD_GETNAME":
      return {
        ...state,
        fname: action.payload.data.fname
      };
    case "OUTWARD_FORMDATA":
      return action.payload.data;
    case "CANCEL_OUTWARD":
      return initistate;
    default:
      return state;
  }
};
