export const setDeggrp = (state = [], action) => {
  switch (action.type) {
    case "GET_SET_DEGGRP":
      return action.payload.data;
    case "CLEAR_SET_DEGGRP":
      return [];
    default:
      return state;
  }
};

export const getExamMode = (state = [], action) => {
  switch (action.type) {
    case "GET_EXAM_MODE":
      return action.payload.data;
    case "CLEAR_EXAM_MODE":
      return [];
    default:
      return state;
  }
};

export const getDegree = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_EXAM":
      return action.payload.data;
    case "CLEAR_DEGREE_EXAM":
      return [];
    default:
      return state;
  }
};
