export const getAttColl = (state = [], action) => {
  switch (action.type) {
    case "GET_ATT_COLL":
      return action.payload.data;
    case "CLEAR_ATT_COLL":
      return [];
    default:
      return state;
  }
};

export const getAttDegree = (state = [], action) => {
  switch (action.type) {
    case "GET_ATT_DEGREE":
      return action.payload.data;
    case "CLEAR_ATT_DEGREE":
      return [];
    default:
      return state;
  }
};

export const getSemDegr = (state = [], action) => {
  switch (action.type) {
    case "GET_ATT_SEM":
      return action.payload.data;
    case "CLEAR_ATT_SEM":
      return [];
    default:
      return state;
  }
};

export const getSecSem = (state = [], action) => {
  switch (action.type) {
    case "GET_ATT_SEC":
      return action.payload.data;
    case "CLEAR_ATT_SEC":
      return [];
    default:
      return state;
  }
};

export const viewAttSet = (state = [], action) => {
  switch (action.type) {
    case "GET_ATT_VIEW":
      return action.payload.data;
    case "CLEAR_ATT_VIEW":
      return [];
    default:
      return state;
  }
};
