const initstate = {
  mainlvl: {
    fdegree: "",
    fexamno: "",
    fsubcode: "",
    fsubshort: "",
    fsubname: "",
    fmaxmarks: "",
    fminmarks: "",
    fcredits: "",
    fthcr: "",
    fprcr: "",
    fblockno: "",
    fcarryupto: "",
    fdeptcode: "",
    fmandatory: "F",
    fsuspend: "F",
    fltp: "",
    fsubsidary: "",
    fmarkdesc1: "",
    fmarkdesc2: "",
    fboard: "",
    fprintord: "",
    fexsubcode: "",
    ftheorymin: "0",
    fpractmin: "0",
    fthelig: "",
    fprelig: "",
    fthhrs: "",
    fprhrs: "",
  },
  sublvl: [
    {
      fssubcode: "0",
      fssubname: "",
      fshortname: "",
      ftheory: "F",
      fintass: "F",
      fretain: "F",
      fgroup: "",
      fsmaxmarks: "",
      fsminmarks: "",
      fparentsub: "",
      fmodmarks: "",
      fgrace: "",
      fcodeno: "",
      fqpcode: "",
      fvalmax: "",
    },
  ],
};

export const getExmNo = (state = [], action) => {
  switch (action.type) {
    case "FETCH_EXAMNO":
      return action.payload.data;
    default:
      return state;
  }
};

export const getSubDet = (state = initstate, action) => {
  switch (action.type) {
    case "GET_SUBJECTS":
      return action.payload.data;
    default:
      return state;
  }
};

export const getSubData = (state = initstate, action) => {
  switch (action.type) {
    case "GET_SUBJECT_DATA":
      return action.payload.data;

    case "CHANGE_SUB_DATA":
      const { name, value } = action.payload;

      return {
        sublvl: [...state.sublvl],
        mainlvl: { ...state.mainlvl, [name]: value },
      };

    case "CHANGE_SUB_LVL":
      let { el, i } = action.payload;
      const newSublvl = state.sublvl.map((item, j) => {
        let data = item;
        if (j === i) data = { ...el };
        return data;
      });
      return {
        mainlvl: { ...state.mainlvl },
        sublvl: newSublvl,
      };

    case "SUCC_SUBJECT":
      return initstate;

    case "CANCEL_DET":
      return initstate;

    case "ADD_SUBLVL":
      initstate.sublvl[0].fssubcode = state.sublvl.length;
      return {
        mainlvl: { ...state.mainlvl },
        sublvl: [...state.sublvl, initstate.sublvl[0]],
      };

    case "GET_OLD_SUBJECT_DATA":
      action.payload.data.mainlvl.fdegree = state.mainlvl.fdegree;
      action.payload.data.mainlvl.fexamno = state.mainlvl.fexamno;
      action.payload.data.mainlvl.fsubcode = state.mainlvl.fsubcode;

      return action.payload.data;

    default:
      return state;
  }
};
