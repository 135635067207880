export const getrvrtdeggrp = (state = [], action) => {
  switch (action.type) {
    case "RVRT_DEGGRP":
      return action.payload.data;
    case "CLEAR_RVRT_DEGGRP":
      return [];
    default:
      return state;
  }
};

export const getrvrtexamno = (state = [], action) => {
  switch (action.type) {
    case "RVRT_EXAMNO":
      return action.payload.data;
    case "CLEAR_RVRT_EXAMNO":
      return [];
    default:
      return state;
  }
};

export const getrvrtqpcode = (state = [], action) => {
  switch (action.type) {
    case "RVRT_SEM":
      return action.payload.data;
    case "CLEAR_RVRT_SEM":
      return [];
    default:
      return state;
  }
};

export const getrvrttable = (state = [], action) => {
  switch (action.type) {
    case "RVRT_TABLE":
      return action.payload.data;
    case "CLEAR_RVRT_TABLE":
      return [];
    default:
      return state;
  }
};
