export const getDegreeGroupDegReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_RVDEGREEGRP":
      return action.payload.data;
    case "CLEAR_RVDEGREEGRP":
      return [];
    default:
      return state;
  }
};

export const getRvDatesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_RVDATE":
      return action.payload.data;
    case "CLEAR_RVDATE":
      return [];
    default:
      return state;
  }
};

export const getRVStatsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_RVSTATS":
      return action.payload.data;
    case "CLEAR_RVSTATS":
      return [];
    default:
      return state;
  }
};

export const getRVQPDeatilsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_QPDETAILS":
      return action.payload.data;
    case "CLEAR_QPDETAILS":
      return [];
    default:
      return state;
  }
};
