export const loaduniversities = (state = [], action) => {
  switch (action.type) {
    case "GET_UNIVERSITY_DATA":
      return action.payload.data;
    default:
      return state;
  }
};
export const loadtickets = (state = [], action) => {
  switch (action.type) {
    case "GET_TICKETS_DATA":
      return action.payload;
    case "CLEAR_TICKETS_DATA":
      return [];
    default:
      return state;
  }
};
// if(){

// }else{}
export const loadempl = (state = [], action) => {
  switch (action.type) {
    case "GET_TICKETS_DATA_EMPL":
      return action.payload || state;
    case "CLEAR_EMPL_DATA":
      return [];
    default:
      return state;
  }
};

export const loademploye = (state = [], action) => {
  switch (action.type) {
    case "GET_TICKET_ASSIGN_DATA":
      return action.payload.data;
    case "CLEAR_TICKET_ASSIGN_DATA":
      return [];
    default:
      return state;
  }
};

export const AssignTicketto = (state = [], action) => {
  switch (action.type) {
    case "GET_TICKETS_ASSIGNED":
      return action.payload.data;
    case "CLEAR_TICKET_ASSIGN_DATA":
      return [];
    default:
      return state;
  }
};
