const initstate = {
  deggrp: [],
  fmsgtype: "SMS",
  fsendto: "",
  fevent: "GEN",
  fcollfrom: "0000",
  fcollto: "ZZZZ",
  fdegfrom: "00000",
  fdegto: "ZZZZZ",
  fregfrom: "00000",
  fregto: "ZZZZZ",
  fteachfrom: "0000",
  fteachto: "ZZZZ",
  fboardfrom: "0000",
  fboardto: "ZZZZ",
  ftitle: "",
  fmsg: "",
  fevents: [],
  fresevent: "",
  fedit: "T",
  ffeepaid: "ALL",
};

const smsReducer = (state = initstate, action) => {
  switch (action.type) {
    case "GET_EVENTS":
      return { ...state, fevents: action.payload.data };
    case "FILTER_MSG":
      const fevent = action.payload.value;
      const msgobj = state.fevents.filter((el, i) => {
        if (el.fevent == fevent) return el;
      })[0];
      return {
        ...state,
        fmsg: msgobj.fmessage,
        fedit: msgobj.fedit,
        ftitle: msgobj.ftitle,
        ftemplate: msgobj.ftemplate,
      };
    case "CHANGE_SMS":
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case "CLEAR_SMS":
      return initstate;
    default:
      return state;
  }
};

const viewSMSReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_MESSAGES":
      return action.payload.data.data;
    default:
      return state;
  }
};

const viewNotifReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS":
      return action.payload.data.data;
    default:
      return state;
  }
};

const getCollegeDetails = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGES":
      return action.payload.data;
    default:
      return state;
  }
};

const getColleges = (state = [], action) => {
  switch (action.type) {
    case "GET_COLLEGES_Det":
      return action.payload.data;
    default:
      return state;
  }
};

const getDegrees = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREES":
      return action.payload.data;
    default:
      return state;
  }
};

const getDegreefromDeggrp = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGREE_FROM_DEGGRP":
      return action.payload.data;
    default:
      return state;
  }
};

export {
  smsReducer,
  viewSMSReducer,
  viewNotifReducer,
  getCollegeDetails,
  getDegrees,
  getDegreefromDeggrp,
  getColleges,
};
