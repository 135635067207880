export const resultstatsreportdeglist = (state = [], action) => {
  switch (action.type) {
    case "RESULT_STATS_DEGREE_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

export const resultstatsreportclglist = (state = [], action) => {
  switch (action.type) {
    case "RESULT_STATS_COLLEGE_LIST":
      return action.payload.data;
    default:
      return state;
  }
};

export const restatloaddeg = (state = [], action) => {
  switch (action.type) {
    case "LOAD_DEGREE_RES":
      return action.payload.data;
    default:
      return state;
  }
};

export const restatloadcoll = (state = [], action) => {
  switch (action.type) {
    case "LOAD_COLLEGE_RES":
      return action.payload.data;
    default:
      return state;
  }
};
